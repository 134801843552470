import React, {useMemo,useEffect} from "react";
import ReactDOM from 'react-dom';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  height: '180px',
  justifyContent: 'center'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

class Fileupload extends React.Component{

  upload(file){
    console.log(file)
    var formData = new FormData()
    formData.append('file', file)
    console.log(formData)
    const req_url = "/api/v1/upload";
    const request = new XMLHttpRequest();
  
    request.open("POST", req_url, true);
    request.onreadystatechange = () => {
      if (request.readyState === 4 && request.status === 200) {
        console.log(request.responseText)
        var post_obj = JSON.parse(request.responseText)
        post_obj['docname'] = props.obj.doc_name
        post_obj['doctype'] = 'File'
        post_obj['doc_id'] = props.obj.doc_id
        $.ajax({
          type: "POST",
          url: '/api/v1/submit_data',
          data: JSON.stringify({obj: post_obj}),
          datatype : "application/json",
          contentType: "application/json; charset=utf-8",
          success: function(data){
            console.log(data);
          }
        })
      }
    };
    request.send(formData);
  }
  
  /*const fileUpload = acceptedFiles.map(file=>(
      upload(file)
  ));*/
  
  render(){
    return (
      <div className="container">
        <div>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div>
        </div>
        <aside>
          <h4>Files</h4>
          <ul>{files}</ul>
        </aside>
      </div>
    );
  }

}

export default Fileupload