import React from "react";
import ReactDOM from 'react-dom';
import  { useEffect } from 'react';
import useDrivePicker from 'react-google-drive-picker'
import $ from 'jquery';

function Picker({obj}) {
    
      const [openPicker, data, authResponse] = useDrivePicker();  
      // const customViewsArray = [new google.picker.DocsView()]; // custom view
      const handleOpenPicker = () => {
        openPicker({
          clientId: "414718274385-hng3ir6p3r6uqok4s1dkqo92vr704isd.apps.googleusercontent.com",
          developerKey: "AIzaSyCd_s0G73vBnQtY03CmW9udYXghKQ8IAuc",
          viewId: "DOCS",
          token: $('#acc_token').val(), // pass oauth token in case you already have one
          showUploadView: true,
          showUploadFolders: false,
          supportDrives: false,
          multiselect: true,
          callbackFunction: (data) => {
            if (data.action === 'cancel') {
              console.log('User clicked cancel/close button')
            }
            console.log(data)
            if (!!data) {
              if(!!obj.submitWithForm){
                var fileNames = "";
                var urlStr = "";// obj.urlStr;
                var idStr = "";
                 for(var i=0; i<data.docs.length; i++){
                     fileNames = fileNames + "," + data.docs[i].name;
                     urlStr = data.docs[i].url + "," + urlStr ;
                     idStr = data.docs[i].id + "," + idStr ;
                 }
                
                let filenameSelector = !!obj.filenameField ? "#"+ obj.filenameField : '#fileNames'
                $(filenameSelector).html("Selected: "+fileNames)
                let urlFieldSelector = "#"+obj.urlField
                $(urlFieldSelector).val(urlStr).trigger('change')
                let idFieldSelector = "#"+obj.idField
                $(idFieldSelector).val(idStr).trigger('change')
              }else{
                $('#pickerBtnSpinner').show()
                var fileArr = [];
                var urlStr = "";
                var fileNames = "";
                 for(var i=0; i<data.docs.length; i++){
                     urlStr = data.docs[i].url + "," + urlStr ;
                     fileNames = data.docs[i].name + "," + fileNames ;
                     fileArr.push({"id": data.docs[i].id, "folder": obj.destinationFolder, "folder_id": obj.destinationFolderId })
                 }
                var file_obj = {"ssid": obj.ssid,
                    fileArr: fileArr,
                    ssid: obj.ssid,
                    sht: obj.sht,
                    rowNum: obj.rowNum,
                    fileColumn: obj.destination
                }
                console.log(file_obj)
                var file_obj_str = JSON.stringify(file_obj);
                var file_req_url = "/api/v1/script_api?email="+obj.email+"&sol=2&function=moveFilesAndRecordDetails&values[]="+encodeURIComponent(file_obj_str) ;
                  $.get(file_req_url, function(data, status){
                    $('#pickerBtnSpinner').hide()
                    window.location.reload(true) //location.replace(url1);
                  });
                  
                record_action(obj.action,obj.by,obj.donefor,"",urlStr)
                
              } 
        }
          },
        })
      }
      
      return (
        <div>
            <button onClick={() => handleOpenPicker()} className="btn btn-primary btn-sm" type="button">
              <span className="spinner-border spinner-border-sm" id="pickerBtnSpinner" style={{display: "none"}}></span>
              {!!obj.buttonTitle ? obj.buttonTitle : "Upload Files"}
            </button>
        </div>
      );
    
}

export default Picker