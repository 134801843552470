import React from "react";
import ReactDOM from 'react-dom';
import Dropzone from 'react-dropzone';
import $ from 'jquery';

class Form1 extends React.Component{
  
    constructor(props) {
        super(props)
        this.state = {formObj: this.props.formObj, checkboxValues: []}
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onDrop = (files) => {
          let obj = this.state.formObj
          var filesarr = []
          files.forEach(function(file){
             console.log(file)
             filesarr.push(file)
          })
          obj['fields'][this.state.selectedIndx]['value'] = {files: filesarr}
          obj['fields'][this.state.selectedIndx]['fileDisplay'] = files.map(file => (
            <li key={file.name}>
              {file.name} - {file.size} bytes
            </li>
          ))
          this.setState({formObj: obj})
        };
        
        this.state.fileObj = {
          files: []
        };
    }
    
    handleClick(event){
      this.setState({ selectedIndx : event.target.parentElement.parentElement.parentElement.getAttribute('indx') })
    }
    
    handleChange(event) {
        var indx = event.target.getAttribute('indx')
        let obj = this.state.formObj
        obj['fields'][indx]['value'] = event.target.value
        for(var i=0; i<obj['fields'].length; i++){
          if(obj['fields'][i]['type']=='disabled'){
            obj['fields'][i]['value'] = document.getElementById(obj['fields'][i]['key']).value
          }else if(obj['fields'][i]['type']=='multiselect'){
            let multiSelect = document.getElementById(obj['fields'][i]['key']);
              let selectedOptions = Array.from(multiSelect.children);
              let result = [];

              selectedOptions.forEach(el => {
                result.push(el.value);
              });
           obj['fields'][i]['value'] = result
          }
        }
        this.setState({ formObj : obj })
    }
    
    handleSubmit(event) {
       
        function replaceGlobally(original, searchTxt, replaceTxt) {
          const regex = new RegExp(searchTxt, 'g');
          return original.replace(regex, replaceTxt);
        }
        
        function performReplacement(original, replacements) {
          let result = original;
          for (const [key, value] of Object.entries(replacements)) {
            let k = '#' + key + '#';
            let val = value;
            result = replaceGlobally(result, k, val);
          }
          return result;
        }
        
        event.preventDefault()
        $('#formBtnSpinner').show()
        $('#loading').show()
        $('button').prop('disabled', true)
        
        let obj = this.state.formObj
        let formFields = obj.fields
        var post_obj = {}
        let requestsObj = []
        for(var i=0;i<formFields.length;i++){
            if(formFields[i]['type']=='file' && !!formFields[i]['value'] && !!formFields[i]['value']['files'] ){
             var files = formFields[i]['value']['files']
              var fileInfoArr= []
              for(var j=0; j<files.length; j++){
                var formData = new FormData()
                formData.append('file', files[j])
                const req_url = "/api/v1/upload";
                const request = new XMLHttpRequest();
              
                request.open("POST", req_url, false);
                request.onreadystatechange = () => {
                  if (request.readyState === 4 && request.status === 200) {
                    let parsedResponse = JSON.parse(request.responseText)
                    fileInfoArr.push(parsedResponse)
                    
                    let filePostObj = {
                      docname:'Solution Images',
                      doctype: 'File',
                      solution_id: obj.solution_id,
                      company_id: obj.company_id,
                      user_id: obj.user_id,
                      solution_template_id: obj.solution_template_id ? obj.solution_template_id : null,
                      api_request_source: 'JS',
                      ...parsedResponse
                    }
                    requestsObj.push(filePostObj)
                  }
                };
                request.send(formData);
              }
              formFields[i]['value'] = fileInfoArr
              post_obj[formFields[i]['key']] = formFields[i]['value']
            }else if(formFields[i]['type']=='html'){
              formFields[i]['value']=CKEDITOR.instances[formFields[i]['key']].getData()
              post_obj[formFields[i]['key']] = formFields[i]['value']
            }else if(formFields[i]['type']=='multiselect'){
              
              let multiSelect = document.getElementById(formFields[i]['key']);
              let selectedOptions = Array.from(multiSelect.children);
              let result = [];

              selectedOptions.forEach(el => {
                result.push(el.value);
              });
              post_obj[formFields[i]['key']] = result
            }else{
              post_obj[formFields[i]['key']] = formFields[i]['value']
            }
        }
        
        post_obj['solution_id'] = obj.solution_id
        post_obj['company_id'] = obj.company_id
        post_obj['user_id'] = obj.user_id
        post_obj['api_request_source'] = 'JS'
        post_obj['docname'] = obj.docname
        post_obj['doctype'] = obj.doctype

        if(!obj.newEntry && !!obj.oid){
          var req_url = '/api/v1/update_data'
          var req_data = JSON.stringify({obj: post_obj, id: obj.oid, user: obj.user_email, key: obj.user_api_key})
        }else{
          var req_url = '/api/v1/submit_data'
          var req_data = JSON.stringify({obj: post_obj, user: obj.user_email, key: obj.user_api_key})
        }
        $.ajax({
          type: "POST",
          url: req_url,
          data: req_data,
          datatype : "application/json",
          contentType: "application/json; charset=utf-8",
          success: function(data){
            console.log(data)
            if(!!obj.action){
              
              let newAction = performReplacement(obj.action, post_obj);
              
              let newDoneFor = obj.donefor;
              if (newDoneFor) {
                newDoneFor = performReplacement(newDoneFor, post_obj);
              }
              let newDetails = obj.details;
              if (newDetails) {
                newDetails = performReplacement(newDetails, post_obj);
              }
              
              record_user_action(newAction,obj.by,newDoneFor,obj.link,newDetails,data['doc_id']['$oid'])
            }
            
            if(requestsObj && requestsObj.length > 0){
              let totalSumbissions = 0
              requestsObj.forEach(o=>{
                let file_req_url = '/api/v1/submit_data'
                o['doc_id'] = data['doc_id']['$oid']
                let file_req_data = JSON.stringify({obj: o, user: obj.user_email, key: obj.user_api_key})
                $.ajax({
                  type: "POST",
                  url: file_req_url,
                  data: file_req_data,
                  datatype : "application/json",
                  contentType: "application/json; charset=utf-8",
                  success: function(data){
                    totalSumbissions++
                    if(totalSumbissions == requestsObj.length){
                      $('button').prop('disabled', false)
                      if(obj.nextUrl == "refresh"){
                          window.location.reload(true)    
                      }else if(!!obj.nextUrl){
                          window.location.href = obj.nextUrl + "?oid=" + data['doc_id']['$oid']
                      }else{
                          $(event.target).find('.alert-success').show();
                          $('#formBtnSpinner').hide()
                          $('#loading').hide()
                          setTimeout(function(){
                            $(event.target).find('.alert-success').hide();
                          },2000)
                      }
                    }
                  },
                  error: function(data){
                    totalSumbissions++
                    if(totalSumbissions == requestsObj.length){
                      $('button').prop('disabled', false)
                      if(obj.nextUrl == "refresh"){
                          window.location.reload(true)    
                      }else if(!!obj.nextUrl){
                          window.location.href = obj.nextUrl + "?oid=" + data['doc_id']['$oid']
                      }else{
                          $(event.target).find('.alert-success').show();
                          $('#formBtnSpinner').hide()
                          $('#loading').hide()
                          setTimeout(function(){
                            $(event.target).find('.alert-success').hide();
                          },2000)
                      }
                    }
                  },
                })
              })
            }else{
              $('button').prop('disabled', false)
              if(obj.nextUrl == "refresh"){
                  window.location.reload(true)    
              }else if(!!obj.nextUrl){
                  window.location.href = obj.nextUrl + "?oid=" + data['doc_id']['$oid']
              }else{
                  $(event.target).find('.alert-success').show();
                  $('#formBtnSpinner').hide()
                  $('#loading').hide()
                  setTimeout(function(){
                    $(event.target).find('.alert-success').hide();
                  },2000)
              }
            }
          },
          error: function(err){
            console.log(err);
            $('#loading').hide()
            $(event.target).find('.alert-danger').show();
            setTimeout(function(){
                window.location.reload(true)  
            },1000)
          }
        });
    }
    
    render(){
        const container = {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '10px',
          borderWidth: 2,
          borderRadius: 2,
          borderColor: '#eeeeee',
          borderStyle: 'dashed',
          backgroundColor: '#fafafa',
          color: '#bdbdbd',
          outline: 'none',
          transition: 'border .24s ease-in-out',
          justifyContent: 'center'
        }
       
       
        var formLen = this.state.formObj.fields.length
        var formFieldsObj = this.state.formObj.fields
        var fields = []
        var requiredFields = this.state.formObj.requiredFields
        for(var i=0; i<formLen; i++){
            var reqdFlag = (!!this.state.formObj.fields[i].required || requiredFields.indexOf(this.state.formObj.fields[i].label) >=0) ? true : false
            //console.log(this.state.formObj.fields[i])
            if(this.state.formObj.fields[i].type=='hidden'){
                fields.push(<div className="mb-3">
                              <input className="form-control form-control-sm" id={this.state.formObj.fields[i].key} type={this.state.formObj.fields[i].type} value={this.state.formObj.fields[i].value} onChange={this.handleChange} indx={i} />
                            </div>)    
            }else if(this.state.formObj.fields[i].type == 'disabled'){
                fields.push(<div className="mb-3">
                              <label className="form-label" for={this.state.formObj.fields[i].key}>{this.state.formObj.fields[i].label}</label>
                              <input className="form-control form-control-sm" id={this.state.formObj.fields[i].key} type="text" value={this.state.formObj.fields[i].value} indx={i} disabled={true}/>
                            </div>)  
            }else if(this.state.formObj.fields[i].type.indexOf('multiselect')>=0){
              let options = [];
              // let selectedValues = this.state.formObj.fields[i].value || [];
  
              let selectedValues = [];
              if (this.state.formObj.fields[i].value) {
                if (Array.isArray(this.state.formObj.fields[i].value)) {
                  selectedValues = this.state.formObj.fields[i].value;
                } else if (typeof this.state.formObj.fields[i].value === 'string') {
                  selectedValues = this.state.formObj.fields[i].value.split(',');
                  selectedValues = selectedValues.map((item) => item.trim());
                } 
              }


              let optionList = this.state.formObj.fields[i].options;
              let maxItems = this.state.formObj.fields[i].max_items ? this.state.formObj.fields[i].max_items : "";
            
              for (let j = 0; j < optionList.length; j++) {
                let isSelected = selectedValues.includes(optionList[j].value);
                options.push(
                  <option
                    value={optionList[j].value}
                    key={'key-' + optionList[j].value}
                    disabled={isSelected}
                  >
                    {optionList[j].text}
                  </option>
                );
              }
            
              fields.push(
                <div className="mb-3" key={this.state.formObj.fields[i].key}>
                  <input
                    type="hidden"
                    id={this.state.formObj.fields[i].key + "-options"}
                    value={JSON.stringify(optionList)}
                    data-selected-values={JSON.stringify(selectedValues)}
                    data-max-items={maxItems}
                  />
                  <label className="form-label" htmlFor={this.state.formObj.fields[i].key}>
                    {this.state.formObj.fields[i].label}
                  </label>
                  <select
                    className="form-select js-multichoice"
                    id={this.state.formObj.fields[i].key}
                    onChange={this.handleChange}
                    indx={i}
                    required={reqdFlag}
                    multiple={true}
                    style={{height:'45px'}}
                  >
                    
                  </select>
                </div>
              );

                
            }else if(this.state.formObj.fields[i].type.indexOf('select')>=0){
                let options = [<option value="">Select</option>]
                let optionList = this.state.formObj.fields[i].options
                for(var j=0; j<optionList.length; j++){
                    options.push(<option value={optionList[j].value}>{optionList[j].text}</option>)
                }
                fields.push(<div className="mb-3">
                              <label className="form-label" for={this.state.formObj.fields[i].key}>{this.state.formObj.fields[i].label}</label>
                              <select className="form-select form-select-sm" id={this.state.formObj.fields[i].key} value={this.state.formObj.fields[i].value} onChange={this.handleChange} indx={i} required={reqdFlag}>{options}</select>
                            </div>)
            }else if(this.state.formObj.fields[i].type.indexOf('search_dropdown')>=0){
                let options = [<option value="">Select</option>]
                let optionList = this.state.formObj.fields[i].options
            
                for(var j=0; j<optionList.length; j++){
                    if(optionList[j].value == this.state.formObj.fields[i].value){
                      options.push(<option value={optionList[j].value} selected>{optionList[j].text}</option>)
                    }else{
                      options.push(<option value={optionList[j].value}>{optionList[j].text}</option>)
                    }
                }
                fields.push(<div className="mb-3">
                              <label className="form-label" for={this.state.formObj.fields[i].key}>{this.state.formObj.fields[i].label}</label>
                              <select className="form-select form-select-sm js-choice" id={this.state.formObj.fields[i].key} value={this.state.formObj.fields[i].value} onChange={this.handleChange} indx={i} required={reqdFlag}>{options}</select>
                            </div>)
            }else if(this.state.formObj.fields[i].type.indexOf('radio')>=0){
                // let options = [<div className="form-check form-check-inline"><input className="form-check-input" type="radio" name="radioBtn" value="Select"/> <label className="form-check-label" htmlFor="Select">Select</label></div>]
                let options = []
                let optionList = this.state.formObj.fields[i].options
                for(var j=0; j<optionList.length; j++){
                    if(this.state.formObj.fields[i].value === optionList[j].value){
                      options.push(<div className="form-check form-check-inline"><input className="form-check-input" type="radio" name={`radioBtn${i}`} onChange={this.handleChange} value={optionList[j].value} indx={i} checked="checked" /> <label className="form-check-label" htmlFor={`radioLabel${i}`}>{optionList[j].text}</label></div>)
                    }
                    else{
                      options.push(<div className="form-check form-check-inline"><input className="form-check-input" type="radio" name={`radioBtn${i}`} onChange={this.handleChange} value={optionList[j].value} indx={i} /> <label className="form-check-label" htmlFor={`radioLabel${i}`}>{optionList[j].text}</label></div>)
                    }
                }
                fields.push(<div className="mb-3">
                              <label className="form-label" for={this.state.formObj.fields[i].key}>{this.state.formObj.fields[i].label}</label>
                              <div id={this.state.formObj.fields[i].key} required={reqdFlag}>{options}</div>
                            </div>)
            }else if(this.state.formObj.fields[i].type.indexOf('checkbox')>=0){
                let eValuesArr = []
                if("value" in this.state.formObj.fields[i]){
                  let eValues = this.state.formObj.fields[i].value
                  if(eValues!=null){
                    if(eValues.indexOf(',') > -1){
                      eValuesArr = eValues.split(",")
                    }
                    else{
                      eValuesArr = eValues.split(" ")
                    }
                  }
                }

                let values = this.state.formObj.fields[i].value?this.state.formObj.fields[i].value.split(","):[]
                let options = []
                let optionList = this.state.formObj.fields[i].options
                // console.log(optionList)
                for(var j=0; j<optionList.length; j++){
                    if(eValuesArr.includes(optionList[j].value) === false){
                      options.push(<div className="form-check form-check-inline"><input className="form-check-input" type="checkbox" onChange={()=>{
                        if(event.target.checked===true){
                          values.push(event.target.value)
 
                        }
                        else{
                          var index = values.indexOf(event.target.value);
                          values.splice(index, 1);
                        }

                        event.target.value = values.toString()
                        var indx = event.target.getAttribute('indx')
                        let obj = this.state.formObj
                        obj['fields'][indx]['value'] = event.target.value
                        for(var i=0; i<obj['fields'].length; i++){
                          if(obj['fields'][i]['type']=='disabled'){
                            obj['fields'][i]['value'] = document.getElementById(obj['fields'][i]['key']).value
                          }
                        }
                        this.setState({ formObj : obj })
                        console.log("formObj: ",this.state.formObj)
                      }} value={optionList[j].value} indx={i} /> <label className="form-check-label" htmlFor={optionList[j].value}>{optionList[j].text}</label></div>)
                    }
                    else{
                      options.push(<div className="form-check form-check-inline"><input className="form-check-input" type="checkbox" checked="checked" value={optionList[j].value} indx={i} onChange={()=>{
                        if(event.target.checked===true){
                          values.push(event.target.value)
                        }
                        else{
                          var index = values.indexOf(event.target.value);
                          values.splice(index, 1);

                        }
                        event.target.value = values.toString()
                        var indx = event.target.getAttribute('indx')
                        let obj = this.state.formObj
                        obj['fields'][indx]['value'] = event.target.value
                        for(var i=0; i<obj['fields'].length; i++){
                          if(obj['fields'][i]['type']=='disabled'){
                            obj['fields'][i]['value'] = document.getElementById(obj['fields'][i]['key']).value
                          }
                        }
                        this.setState({ formObj : obj })
                        console.log("formObj: ",this.state.formObj)
                      }} /> <label className="form-check-label" htmlFor={optionList[j].value}>{optionList[j].text}</label></div>)
                    }
                }
                fields.push(<div className="mb-3">
                              <label className="form-label" for={this.state.formObj.fields[i].key}>{this.state.formObj.fields[i].label}</label>
                              <div id={this.state.formObj.fields[i].key} required={reqdFlag}>{options}</div>
                            </div>)
            }else if(this.state.formObj.fields[i].type == 'text-area'){
                fields.push(<div className="mb-3">
                              <label className="form-label" for={this.state.formObj.fields[i].key}>{this.state.formObj.fields[i].label}</label>
                              <textarea className="form-control form-control-sm" id={this.state.formObj.fields[i].key} type={this.state.formObj.fields[i].type} value={this.state.formObj.fields[i].value} onChange={this.handleChange} rows="2" indx={i} required={reqdFlag}></textarea>
                            </div>)
            }else if(this.state.formObj.fields[i].type == 'html'){
                fields.push(<div className="mb-3">
                              <label className="form-label" for={this.state.formObj.fields[i].key}>{this.state.formObj.fields[i].label}</label>
                              <input className="form-control form-control-sm htmlEditor4" id={this.state.formObj.fields[i].key} type={this.state.formObj.fields[i].type} value={this.state.formObj.fields[i].value} onChange={this.handleChange} indx={i} required={reqdFlag}/>
                            </div>)
            }else if(this.state.formObj.fields[i].type == 'file'){
                var index = i
                let files = this.state.formObj.fields[i].fileDisplay
                fields.push(<div className="mb-3" onClick={this.handleClick.bind(this)} indx={i}>
                              <label className="form-label" for={this.state.formObj.fields[i].key}>{this.state.formObj.fields[i].label}</label>
                              <Dropzone onDrop={(e) => this.onDrop(e,index)} id={this.state.formObj.fields[i].key} indx={i}>
                                {({getRootProps, getInputProps}) => (
                                  <section className="container" style={container}>
                                    <div {...getRootProps({className: 'dropzone'})} >
                                      <input {...getInputProps()} />
                                      <p>Drag 'n' drop some files here, or click to select files</p>
                                    </div>
                                    <aside>
                                      <ul>{files}</ul>
                                      
                                    
                                      </aside>
                                  </section>
                                )}
                              </Dropzone>
                            </div>)                               
            }else{
                fields.push(<div className="mb-3">
                              <label className="form-label" for={this.state.formObj.fields[i].key}>{this.state.formObj.fields[i].label}</label>
                              <input className="form-control form-control-sm" id={this.state.formObj.fields[i].key} type={this.state.formObj.fields[i].type} value={this.state.formObj.fields[i].value} onChange={this.handleChange} indx={i} required={reqdFlag}/>
                            </div>)    
            }
        }
        var btn = <div className="btn-div">
                    <button className={this.props.formObj.btn.className} >
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="false" id="formBtnSpinner" style={{display: "none"}}></span>
                      {this.props.formObj.btn.label}
                    </button> 
                    <div className="alert alert-success collapse" role="alert" id="form-success-alert">
                      <p className="mb-0 flex-1">Update saved successfully.</p>
                    </div>
                    <div className="alert alert-danger collapse" role="alert" id="form-danger-alert">
                      <p className="mb-0 flex-1">Something went wrong !</p>
                    </div>
                  </div>
                  
                  if(!this.state.formObj['oid'] && this.state.formObj['newEntry'] == ""){
                    return <div><p>Form Data Not Found</p></div>
                  }else{
                    return <div id="dataForm"><div id={`formComponent-${this.state.formObj.id}`}><form onSubmit={this.handleSubmit}>{fields}{btn}</form></div></div>
                  }
                  
    }
}

export default Form1