import React, { useState } from "react";

const CreatePostForm = ({ posts, setPosts }) => {
  const [postContent, setPostContent] = useState("");
  const [hashtags, setHashtags] = useState("");

  const handleContentChange = (e) => {
    setPostContent(e.target.value);
  };

  const handleHashtagsChange = (e) => {
    setHashtags(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let postDetails = {
      user_name: "Shashank",
      user_id: 2877,
      post_id: 25,
      type: "text",
      posted_at: new Date().toISOString().replace(/\.\d+/, ""),
      post_content: postContent,
      likes: [],
      tags: hashtags.split(" "),
      comments: []
    };
    setPosts([...posts, postDetails]);
    setPostContent("");
    setHashtags("");
  };
  console.log(posts);

  return (
    <div className="card mb-3">
      <div className="card-header bg-light overflow-hidden">
        <div className="d-flex align-items-center">
          <div className="avatar avatar-xl status-online rounded-circle d-flex align-items-center bg-soft-secondary">
              <span className='far fa-user w-100'></span>
          </div>
          <div className="flex-1 ms-2">
            <h5 className="mb-0 fs-0">Create post</h5>
          </div>
        </div>
      </div>
      <div className="card-body p-0">
        <form onSubmit={handleSubmit}>
          <textarea
            className="shadow-none form-control rounded-0 resize-none px-card border-y-0 border-200"
            placeholder="What do you want to talk about?"
            rows="4"
            value={postContent}
            onChange={handleContentChange}
          ></textarea>
          <div className="d-flex align-items-center ps-card border border-200">
            <label className="text-nowrap mb-0 me-2" for="hash-tags">
                <span class="fas fa-plus me-1 fs--2"></span>
                <span class="fw-medium fs--1">Add hashtag</span>
            </label>
            <input
              className="form-control border-0 fs--1 shadow-none"
              id="hash-tags"
              type="text"
              placeholder="#Tags"
              value={hashtags}
              onChange={handleHashtagsChange}
            />
          </div>
          <div className="row g-0 justify-content-between mt-3 px-card pb-3">
            <div className="col">
              {/*<button
                className="btn btn-light btn-sm rounded-pill shadow-none d-inline-flex align-items-center fs--1 mb-0 me-1"
                type="button"
              >
                <span className="ms-2 d-none d-md-inline-block">Image</span>
              </button>*/}
            </div>
            <div className="col-auto">
              <button
                className="btn btn-primary btn-sm px-4 px-sm-5"
                type="submit"
              >
                Share
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreatePostForm;
