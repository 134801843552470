import React from "react";
import ReactDOM from 'react-dom';

class Multiselect extends React.Component{
    
    constructor(props) {
        super(props)
        console.log(this.props)
        this.handleClick = this.handleClick.bind(this);
    }
    
    handleClick(event){
        event.preventDefault();
        var indx = Number(event.target.getAttribute('indx'))
        var func = this.props.obj.options[indx]['func']
        var rowNum = this.props.obj.rowNum
        var snum = this.props.obj.snum
        //if(this.props.obj.link == '#'){return}
        if(!!this.props.obj.options[indx]['link']){
            var link = this.props.obj.options[indx]['link'] + '?num=' + this.props.obj.rowNum
            window.open(link)
        }else{
          eval(func)
        }
    }

    
    componentDidMount() {
        //$('.table').DataTable();
    }
    
    render(){
      let optionsArr = this.props.obj.options
      let options = []
      for(var i=0; i<optionsArr.length; i++){
        options.push(<a className="dropdown-item" href={optionsArr[i]['link']} func={optionsArr[i]['func']} onClick={this.handleClick} indx={i}>{optionsArr[i]['label']}</a>)
      }
      
      return <div className="dropdown font-sans-serif d-inline-block mb-2">
                <button className={this.props.obj.className} id="dropdownMenuButton" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{this.props.obj.btnText}</button>
                <div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuButton">
                  {options}
                </div>
              </div>
        
    }
    
}

export default Multiselect