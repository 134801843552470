import React from "react";
import ReactDOM from 'react-dom';

class DropdownButton1 extends React.Component{
    
    constructor(props) {
        super(props)
        // console.log(this.props)
        this.handleClick = this.handleClick.bind(this);
    }
    
    handleClick(event){
        event.preventDefault();
        var indx = Number(event.target.getAttribute('indx'))
        var func = this.props.obj.options[indx]['func']
        var rowNum = this.props.obj.rowNum
        var snum = this.props.obj.snum
        //if(this.props.obj.link == '#'){return}
        if(this.props.obj.options[indx]['link'] == '#'){
            let link = this.props.obj.options[indx]['link'] + '#'
        }
        else if(!!this.props.obj.options[indx]['link'] && this.props.obj.options[indx]['link'] != '#'){
            let link = this.props.obj.options[indx]['link'] + '?oid=' + this.props.obj._id
            window.open(link)
        }else{
          const functionName = func.split('(')[0]; // Get the function name from the string
          const argumentsString = func.substring(func.indexOf('(') + 1, func.indexOf(')')); // Get the argument string from the string
          const argument = argumentsString.split(',').map(arg => arg.trim()); // Split the argument string into an array of argument names
          
          const functionReference = window[functionName];
          if (typeof functionReference === 'function') {
            const args = argument.map(arg => eval(arg)); // Evaluate the arguments in the current context to get their values
            functionReference(...args); // Spread the arguments to pass them to the function
          } else {
            alert('The function has not been declared')
          }
        }
    }

    
    componentDidMount() {
        //$('.table').DataTable();
    }
    
    render(){
      let optionsArr = this.props.obj.options
      let options = []
      for(var i=0; i<optionsArr.length; i++){
        options.push(<a key={`dropdownItem${i}`} className="dropdown-item" href={optionsArr[i]['link']} func={optionsArr[i]['func']} onClick={this.handleClick} indx={i}>{optionsArr[i]['label']}</a>)
      }
      
      if(this.props.obj.allowDeletion){
        options.push(<a key={`dropdownDeleteItem${i}`} className="dropdown-item text-danger" onClick={this.props.onDelete} indx={i}>Delete</a>)
      }
      
      return <div className="dropdown font-sans-serif d-inline-block mb-2">
                <button className={this.props.obj.className} id="dropdownMenuButton" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{this.props.obj.btnText}</button>
                <div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuButton">
                  {options}
                </div>
              </div>
        
    }
    
}

export default DropdownButton1