import React from "react";
import ReactDOM from 'react-dom';
import $ from 'jquery';

class Todolist extends React.Component{
    constructor(props) {
        super(props)
        this.state = {obj: this.props.obj}
        this.handleChange = this.handleChange.bind(this);
    }
    
    handleChange(event){
        let indx = event.target.getAttribute('indx')
        let obj = this.state.obj
        /*obj['json'][indx]['Status'] = 'Closed'
        let task = obj['json'][indx]['Task'];
        let link = obj['link'] + "?num=" + obj['json'][indx]['RowNum'];*/
        var req_obj = {
            ssid: obj['ssid'],
            sht: obj['sht'],
            rowNum: obj['json'][indx]['RowNum'],
            fields: [{'label':'Status',value:'Closed',type:'text'}, {label:'Last Updated On', value: new Date(), type: 'text'}]
        }
        let submitUrl = obj['submitUrl']
        let obj_str = JSON.stringify(req_obj)
        let req_url = submitUrl + encodeURIComponent(obj_str)
        
        $.get(req_url, function(data, status){
            console.log(data)
            console.log(status)
            record_action('Task completed - ',obj['user'],obj['json'][indx]['Task'],"",obj['link']+"?num="+obj['json'][indx]['RowNum'],obj['json'][indx]['RowNum'])
            window.location.reload()
        })
    }

    render(){
        var rowNum = this.props.obj.json.length
        var rows = []
        var months = ["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"]
        var todos = this.state.obj.json.sort(function(x, y){
                                                return new Date(x['Due Date']) - new Date(y['Due Date']);
                                            })
        this.state.obj.json = todos
                                            
        for(var i=0; i<rowNum; i++){
            if(!!this.state.obj.json[i]['Task'] && this.state.obj.json[i]['Status'] != ("Closed"||"Deleted") ){
                var itemId = "todo-" + i.toString();
                var date = new Date(this.props.obj.json[i]['Due Date'])
                var monthVal = months[date.getMonth()]
                var dateVal = date.getDate()
                var checkbox = this.props.obj.insertcheckbox ?
                              <input className="form-check-input rounded-3 form-check-line-through p-2 mt-0 form-check-input-undefined" type="checkbox" id={itemId} onChange={this.handleChange} indx={i}/>
                              : ""  
                var task = this.props.obj.link ? 
                        (<a href={this.props.obj.link + '?num=' + this.props.obj.json[i]['RowNum'] } >
                            <span className="text-800 fs-0">{this.props.obj.json[i]['Task']}</span>
                        </a>)
                        : (<span className="text-800 fs-0">{this.props.obj.json[i]['Task']}</span>)
                var priority = this.props.obj.json[i]['Priority'] ?         
                            <small className="badge fw-semi-bold rounded-pill badge-soft-primary p-2">{this.props.obj.json[i]['Priority']}</small> : ""
                var project = this.props.obj.json[i]['Project'] ?
                            <small className="badge fw-semi-bold rounded-pill badge-soft-warning p-2">{this.props.obj.json[i]['Project']}</small> : ""
                var owner = this.props.obj.json[i]['Owner'] ?         
                            <small className="badge fw-semi-bold rounded-pill badge-soft-success p-2">{this.props.obj.json[i]['Owner']}</small> : ""
                
                rows.push(<div className="d-flex justify-content-between hover-actions-trigger btn-reveal-trigger px-card hover-bg-100">
                            <div className="form-check mb-0 d-flex align-items-center">
                              {checkbox}
                              <label className="form-check-label mb-0 p-3" >
                                {task}<br/>
                                <span className="fs--1 text-600">{this.props.obj.json[i]['Description']}</span><br/>
                                {priority}{project}{owner}
                              </label>
                                <div className="calendar p-0">
                                    <span className="calendar-month fs--1">{monthVal}</span>
                                    <span className="calendar-day fs-0">{dateVal}</span>
                                </div>
                            </div>
                          </div>)
            }
        }
        return <div>{rows}</div>
    }
}

export default Todolist