import React from "react";
import ReactDOM from 'react-dom';
import $ from 'jquery';

class Details extends React.Component{
    constructor(props) {
        super(props)
        console.log(this.props)
    }
    
    render(){
    var rowNum = this.props.obj.json[0].length
    var colNum = this.props.obj.headers.length
    var items = []
    //var header = []
    for(var i=0; i<colNum; i++){
        if(this.props.obj.flags[i]=="D" || this.props.obj.flags[i]=="ALL"){
            let key = this.props.obj.headers[i]
            items.push(<div class="row g-0 align-items-center py-2 position-relative border-bottom border-200">
                        <div class="col ps-card py-1 position-static">
                          <div class="d-flex align-items-center">
                            <div class="flex-1">
                              <h6 class="mb-0 d-flex align-items-center">{key}</h6>
                            </div>
                          </div>
                        </div>
                        <div class="col py-1">
                          <div class="row g-0">
                            <div class="col-auto pe-2">
                              <div class="fs--1 fw-semi-bold">{this.props.obj.json[0][key]}</div>
                            </div>
                          </div>
                        </div>
                      </div>)
        }
    }

    return  <div>{items}</div>
    }
}

export default Details