import React from "react";
import ReactDOM from 'react-dom';

const IntroComponent = ({message}) => {
  return (
    <div>
      Solution Name: {message}
    </div>
  )
}

export default IntroComponent