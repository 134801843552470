import React, { useState } from "react";

const Post = ({ post, updatePost }) => {
  // You can pass the 'post' object as a prop and access its properties
  const [newComment, setNewComment] = useState("");
  const user_id = 2877
  const handleCommentSubmit = () => {
    let comment = {
      post_id: post.post_id,
      comment_id: post.comments.length,
      user_name: "Shashank",
      user_id: 103,
      commented_at: new Date().toISOString().replace(/\.\d+/, ""),
      comment: newComment
    };
    const updatedPost = {
      ...post,
      comments: [...post.comments, comment]
    };

    updatePost(updatedPost);
    setNewComment("");
  };
  const handleLike = () => {
    const updatedLikes = post.likes.slice();
  
    const userIndex = updatedLikes.indexOf(user_id);
    
    if (userIndex !== -1) {
      updatedLikes.splice(userIndex, 1);
    } else {
      updatedLikes.push(user_id);
    }
  
    const updatedPost = {
      ...post,
      likes: updatedLikes,
    };
  
    updatePost(updatedPost);
    setNewComment("");
};

  
  function formatTime(timestamp) {
    const currentTime = new Date();
    const timestampTime = new Date(timestamp);
    const timeDifference = currentTime - timestampTime;

    if (timeDifference < 1 * 60 * 1000) {
        return "Just now";
    } else if (timeDifference < 60 * 60 * 1000) {
        return Math.floor(timeDifference / 1000 / 60) + " mins ago";
        // return timestampTime.toLocaleString("en-US", { day: "2-digit", month: "2-digit", year: "2-digit", hour: "2-digit", minute: "2-digit" }).replace(",", "");
    } else {
        return timestampTime.toLocaleString("en-US", { day: "2-digit", month: "2-digit", year: "2-digit", hour: "2-digit", minute: "2-digit" }).replace(",", "");
    }
}


  return (
    <div className="card mb-3">
      <div className="card">
        <div className="card-header bg-light">
          <div className="row justify-content-between">
            <div className="col">
              <div className="d-flex">
                  {/* <img className="rounded-circle" src="../../assets/img/team/4.jpg" alt=""> */}
                  <div class="avatar avatar-2xl rounded-circle d-flex align-items-center bg-soft-secondary">
                      {post.profile_img?<img class="rounded-circle" src={post.profile_img} alt="profile image"/>:<span className='far fa-user w-100'></span>}
                  </div>
                <div className="flex-1 align-self-center ms-2 d-flex align-items-center justify-content-between">
                  <p className="mb-1 lh-1">
                    <a className="fw-semi-bold" href="#">
                      {post.user_name}
                    </a>
                  </p>
                  
                </div>
              </div>
            </div>
            <div className="col-auto">
              <div className="dropdown font-sans-serif">
                <button
                  className="btn btn-sm dropdown-toggle p-1 dropdown-caret-none"
                  type="button"
                  id="post-album-action"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></button>
                <div
                  className="dropdown-menu dropdown-menu-end py-3"
                  aria-labelledby="post-album-action"
                >
                  <a className="dropdown-item" href="#!">
                    View
                  </a>
                  <a className="dropdown-item" href="#!">
                    Edit
                  </a>
                  <a className="dropdown-item" href="#!">
                    Report
                  </a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item text-warning" href="#!">
                    Archive
                  </a>
                  <a className="dropdown-item text-danger" href="#!">
                    Delete{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body overflow-hidden">
          <p>{post.post_content}</p>
          {post.tags?.map((t) => (
            <span className="text-primary me-2 fs--1">
              #{t.replace("#", "")}
            </span>
          ))}
          {/* <div className="row mx-n1">
            <div className="col-6 p-1">
              <a href="#" data-gallery="gallery-1"></a>
            </div>
            <div className="col-6 p-1">
              <a href="#" data-gallery="gallery-1"></a>
            </div>
            <div className="col-4 p-1">
              <a href="#" data-gallery="gallery-1"></a>
            </div>
            <div className="col-4 p-1">
              <a href="#" data-gallery="gallery-1"></a>
            </div>
            <div className="col-4 p-1">
              <a href="#" data-gallery="gallery-1"></a>
            </div>
          </div> */}
        </div>
        <div className="card-footer bg-light pt-0">
        <div className="flex-1 align-self-center d-flex align-items-center justify-content-between">
          <div className="fs--1 py-3">
            <a className="text-700" href="#!">
              {post.likes.length} Likes
            </a>{" "}
            •{" "}
            <a className="text-700" href="#!">
              {post.comments.length} Comments
            </a>
          </div>
          <p className="mb-0 fs--1">{formatTime(post.posted_at)} </p>
          </div>
          <div className="row g-0 fw-semi-bold text-center py-2 fs--1">
            <div className="col-auto">
              <div
                className="rounded-2 d-flex align-items-center me-3 cursor-pointer"
                onClick={handleLike}
              >
                {post.likes.indexOf(user_id) != -1 ? (
                  <span className="ms-1 text-danger cursor-pointer">Liked</span>
                ) : (
                  <span className="ms-1 text-primary cursor-pointer">Like</span>
                )}
              </div>
            </div>
            <div className="col-auto d-flex align-items-center">
              <a
                className="rounded-2 text-700 d-flex align-items-center"
                href="#!"
              >
                <span className="ms-1">Share</span>
              </a>
            </div>
          </div>
          <div className='d-flex align-items-center border-top border-200 pt-3'>
            <div class="avatar avatar-xl rounded-circle d-flex align-items-center bg-soft-secondary">
                {post.profile_img?<img class="rounded-circle" src={post.profile_img} alt="profile image"/>:<span className='far fa-user w-100'></span>}
            </div>
            <div className="input-group">
              <input
                className="form-control ms-2 fs--1"
                type="text"
                placeholder="Write a comment..."
                value={newComment}
                onChange={(e) => {
                  setNewComment(e.currentTarget.value);
                }}
              />
              <button className="btn btn-primary" onClick={handleCommentSubmit}>
                send
              </button>
            </div>
          </div>

          {post.comments
            .slice()
            .reverse()
            .map((c) => (
              <>
              
                <div class="d-flex mt-3" key={c.comment_id}>
                    <div class="avatar avatar-xl rounded-circle d-flex align-items-center bg-soft-secondary">
                      {c.profile_img?<img class="rounded-circle" src={c.profile_img} alt="profile image"/>:<span className='far fa-user w-100'></span>}
                    </div>
                    <div class="flex-1 ms-2 fs--1">
                      <p class="mb-1 bg-200 rounded-3 p-2">
                        <a class="fw-semi-bold" href="#">{c.user_name}:</a> 
                        {c.comment}
                    </p>
                      <div class="px-2">{formatTime(c.commented_at)}</div>
                    </div>
                </div>
 
              </>
            ))}
            {post.comments.length >2 && <a className="fs--1 text-700 d-inline-block mt-2" href="#!">
            Load more comments (2 of {post.comments.length} )
          </a>} 
          
        </div>
      </div>
    </div>
  );
};

export default Post;
