import React from "react";
import ReactDOM from 'react-dom';
import $ from 'jquery';

class Form extends React.Component{
    constructor(props) {
        super(props)
        //console.log(this.props)
        this.state = {obj: this.props.obj}
        this.handleChange = this.handleChange.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleChange(event) {
        //console.log(event)
        //console.log(event.target.value)
        //var indx = event.target.getAttribute('indx')
        let obj = this.state.obj
        obj['value'] = event.target.value
        this.setState({ obj : obj })
    }
    
    render(){
        var objArr = this.state.obj.objArr
        for(var i=0; i<objArr.length; i++){
            let options = [<option value="">Select</option>]
            for(var i=0; i<objArr.length; i++){
                options.push(<option value={objArr[i][this.state.obj.valStr]}>{objArr[i][this.state.obj.labelStr]}</option>)
            }
            return <div className="mb-3">
                      <label className="form-label" for={this.state.obj.fieldId}>{this.state.obj.fieldLabel}</label>
                      <select className="form-select form-select-sm" value={this.state.obj.value} onChange={this.handleChange} id={this.state.obj.fieldId}>{options}</select>
                    </div>
            
        }
    }
}

export default Form