import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import * as ReactDOMClient from 'react-dom/client';
import Dropzone from 'react-dropzone';
import $ from 'jquery';

class Fileupload1 extends Component{
  onDrop = (acceptedFiles) => {
    console.log(acceptedFiles);
    var formData = new FormData()
    formData.append('file', acceptedFiles[0])
    console.log(formData)
    const req_url = "/api/v1/upload";
    const request = new XMLHttpRequest();
  
    request.open("POST", req_url, true);
    request.onreadystatechange = () => {
      if (request.readyState === 4 && request.status === 200) {
        console.log(request.responseText);
      }
    };
    request.send(formData);
  }
  
  render() {
    return (
      <Dropzone onDrop={this.onDrop} className="container">
        {({getRootProps, getInputProps}) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} name='file' type="file" />
            Click me to upload a file!
          </div>
        )}
      </Dropzone>
    );
  }
}

export default Fileupload1;