import React from "react";
import ReactDOM from 'react-dom';
import { Pie } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import {
  Chart as ChartJS,
  ArcElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  ArcElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
);

class Piechart extends React.Component{
    constructor(props) {
        super(props)
    }
    
    render(){
      
      let datasets = this.props.obj.datasets
      
      var labels = []
      var chartValues = []
      var backgroundColors = []
      var borderColors = []
      for(var i=0; i<datasets.length; i++){
          labels.push(this.props.obj.datasets[i]['label'])
          chartValues.push(this.props.obj.datasets[i]['value'])
          borderColors.push(this.props.obj.datasets[i]['borderColor'])
          backgroundColors.push(this.props.obj.datasets[i]['backgroundColor'])
        }
      
      
      var data = {
        labels,
        datasets: [{
          label: this.props.obj['label'],
          data: chartValues,
          backgroundColor: backgroundColors,
          borderColor: borderColors
        }]
      }
      
    return <div><Pie data={data} /></div>;
    }
}

export default Piechart