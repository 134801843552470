import React from "react";
import ReactDOM from 'react-dom';
import $ from 'jquery';

class Files extends React.Component{
      constructor(props) {
      super(props)
      console.log(this.props)
      this.handleClick = this.handleClick.bind(this)
    }
    
    handleClick(event){
      console.log(event)
      console.log(event.target)
      var indx = event.target.getAttribute('indx')
      console.log(indx)
      console.log(this.props.files[indx])
      let text = "Are you sure to delete this file?";
      if (confirm(text) == true){
        $('#loading').show();
        var file_url = {fileArr: [this.props.files[indx]]}
        var file_obj_str = JSON.stringify(file_url);
        var file_req_url = "/api/v1/script_api?email="+this.props.email+"&sol=2&function=deleteFiles&values[]="+encodeURIComponent(file_obj_str) ;
        $.get(file_req_url, function(data, status){
        });
        this.props.files.splice(indx,1)
        var urls = this.props.files.reverse().join(",") + ","
        if(!!this.props.fileNames && !!this.props.fileNames[indx] ){
          this.props.fileNames.splice(indx,1)
          var fileNames = this.props.fileNames.reverse().join(",") + ","
        }
        if(!!this.props.thumbnails && !!this.props.thumbnails[indx]){
          this.props.thumbnails.splice(indx,1)
          var thumbnails = this.props.thumbnails.reverse().join(",") + ","
        }
        if(!!this.props.timestamps && !!this.props.timestamps[indx]){
          this.props.timestamps.splice(indx,1)
          var timestamps = this.props.timestamps.reverse().join(",") + ","
        }
        var obj1 = {
          ssid: this.props.ssid,
          sht:this.props.sht,
          rowNum: this.props.rowNum,
          email: this.props.email,
          fields: [{label: this.props.fileColumn, value: urls, type: 'text'},
                  {label: this.props.fileColumn + " - File Names", value: fileNames, type: 'text'},
                  {label: this.props.fileColumn + " - Thumbnails", value: thumbnails, type: 'text'},
                  {label: this.props.fileColumn + " - Timestamps", value: timestamps, type: 'text'}]
        }
        var obj_str = JSON.stringify(obj1)
        var req_url = "/api/v1/script_api?email="+this.props.email+"&sol=2&function=saveNew&values[]="+encodeURIComponent(obj_str) ;
        $.get(req_url, function(data, status){
          window.location.reload();
        });
      }else{
        $('#loading').hide();
        alert('Cancelled');
      }
    }
    
    render(){
      var fileNum = this.props.files.length
      var items = []
      for(var i=0; i<fileNum; i++){
        var fileName = this.props.fileNames.length >0 ? this.props.fileNames[i] : 'File'+(i+1).toString()
        var deleteBtn = !!this.props.allowDeletion ? 
                        <button className="btn btn-danger btn-sm" onClick={this.handleClick} indx={i} >
                          DELETE
                        </button>
                        : ""
        var imageBlockClass = !!this.props.imageBlockClass ? this.props.imageBlockClass : "col-md-2 mb-3" 
        if(!!this.props.thumbnails && !!this.props.thumbnails[i]){
          items.push(<div className={imageBlockClass}>
                      <div align="center" >
                        <a href={this.props.files[i]} target="_blank">
                          <img className="img-thumbnail img-fluid shadow-sm" src={this.props.thumbnails[i]} alt="" width="250" /><br/>
                          <p className="fs-0">
                            { fileName }
                          </p>
                        </a>
                        {deleteBtn}
                      </div>  
                    </div>)
        }else{
          items.push(<div className={imageBlockClass}>
                      <div class="p-1" align="center">
                        <a href={this.props.files[i]} target="_blank">
                          <span className="fas fa-file-alt fs-5"></span><br/>
                          <p class="p-1 fs-0">{ fileName }</p>
                        </a>
                        {deleteBtn}
                      </div>  
                    </div>)
        }
      }
      return  <div class="row">{items}</div>
    }
}

export default Files