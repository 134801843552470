import React, { useState } from "react";
import CreatePostForm from "./CreatePostForm";
import Post from "./Post";

const SocialMediaFeed = ({ data }) => {
  const [posts, setPosts] = useState(data);
  const updatePost = (updatedPost) => {
    const updatedPosts = posts.map((post) =>
      post.post_id === updatedPost.post_id ? updatedPost : post
    );
    setPosts(updatedPosts);
  };
  return (
    <div>
      <CreatePostForm setPosts={setPosts} posts={posts} />
     {posts.sort((a, b) => new Date(b.posted_at) - new Date(a.posted_at)).map((post, index) => (
        <Post key={index} post={post} updatePost={updatePost} />
      ))}

    </div>
  );
};

export default SocialMediaFeed;
