import React from "react";
import ReactDOM from 'react-dom';
import Picker from '../components/Picker';
import $ from 'jquery';

class Form extends React.Component{
    constructor(props) {
        super(props)
        this.state = {formObj: this.props.formObj}
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleChange(event) {
        //console.log(event)
        //console.log(event.target.value)
        var indx = event.target.getAttribute('indx')
        let obj = this.state.formObj
        obj['fields'][indx]['value'] = event.target.value
        //obj['fields'].each(function(f){
        for(var i=0; i<obj['fields'].length; i++){
          if(obj['fields'][i]['type']=='disabled'){
            obj['fields'][i]['value'] = document.getElementById(obj['fields'][i]['id']).value
          }
        }
        this.setState({ formObj : obj })
    }
    
    handleSubmit(event) {
        event.preventDefault()
        $('#formBtnSpinner').show()
        $('button').prop('disabled', true)
        let obj = this.state.formObj
        let formFields = obj.fields
        for(var i=0;i<formFields.length;i++){
            if(formFields[i]['type']=='file'){
                formFields[i]['value']=document.getElementById(formFields[i]['id']).value
                var idStr = document.getElementById(formFields[i]['id']+'idStr').value
                var file_obj = {"ssid": obj.ssid,
                    "idStr": idStr,
                    "folder": formFields[i]['label']
                }
                //console.log(file_obj)
                var file_obj_str = JSON.stringify(file_obj);
                var file_req_url = "/api/v1/script_api?email="+obj.email+"&sol=2&function=moveFilesBulk&values[]="+encodeURIComponent(file_obj_str) ;
                  $.get(file_req_url, function(data, status){
                  });
            }else if(formFields[i]['type']=='html'){
              //formFields[i]['value']=editors[formFields[i]['id']].getData()
              formFields[i]['value']=CKEDITOR.instances[formFields[i]['id']].getData()
            }
        }
        obj.fields = formFields
        let obj_str = JSON.stringify(obj).replace(/%/g,'~~pct~~')
        let req_url = this.state.formObj.submitUrl + encodeURIComponent(obj_str)
        var nextUrl = this.state.formObj.nextUrl
        $.get(req_url, function(data, status){
            console.log(data)
            console.log(status)
            if(status == "success"){ 
                if(!!obj.action){
                    var resp_obj = data['response']['response']['result']
                    var action = !!obj.action_custom_param ? obj.action.replace('#customParam#',document.getElementById(obj.action_custom_param).value) : obj.action 
                    record_action(action,obj.by,obj.donefor,obj.link,obj.details,resp_obj['rowNum'])
                }
                $('button').prop('disabled', false)
                if(nextUrl == "refresh"){
                    window.location.reload(true)    
                }else if(!!nextUrl){
                    window.location.href = nextUrl + "?num=" + data['response']['response']['result']['rowNum']
                }else{
                    $(event.target).find('.alert-success').show();
                    $('#formBtnSpinner').hide()
                    setTimeout(function(){
                      $(event.target).find('.alert-success').hide();
                    },2000)
                }
            }else{
                $(event.target).find('.alert-danger').show();
                setTimeout(function(){
                    window.location.reload(true)  
                },1000)
            }
        })
    }
    
    render(){
        var formLen = this.state.formObj.fields.length
        var formFieldsObj = this.state.formObj.fields
        var fields = []
        var requiredFields = !!this.state.formObj.requiredFields ? this.state.formObj.requiredFields.split(','): []
        for(var i=0; i<formLen; i++){
            var reqdFlag = (!!this.state.formObj.fields[i].required || requiredFields.indexOf(this.state.formObj.fields[i].label) >=0) ? true : false
            //console.log(this.state.formObj.fields[i])
            if(this.state.formObj.fields[i].type=='hidden'){
                fields.push(<div className="mb-3">
                              <input className="form-control form-control-sm" id={this.state.formObj.fields[i].id} type={this.state.formObj.fields[i].type} value={this.state.formObj.fields[i].value} onChange={this.handleChange} indx={i} />
                            </div>)    
            }else if(this.state.formObj.fields[i].type == 'disabled'){
                fields.push(<div className="mb-3">
                              <label className="form-label" for={this.state.formObj.fields[i].id}>{this.state.formObj.fields[i].label}</label>
                              <input className="form-control form-control-sm" id={this.state.formObj.fields[i].id} type="text" value={this.state.formObj.fields[i].value} indx={i} disabled={true}/>
                            </div>)  
            }else if(this.state.formObj.fields[i].type.indexOf('multiselect')>=0){
                fields.push(<div className="mb-3">
                              <input type="hidden" id={this.state.formObj.fields[i].id+"-options"} value={this.state.formObj.fields[i].type.split(":")[1]} />
                              <label className="form-label" for={this.state.formObj.fields[i].id}>{this.state.formObj.fields[i].label}</label>
                              <select className="form-select js-choice" id={this.state.formObj.fields[i].id} value={this.state.formObj.fields[i].value} onChange={this.handleChange} indx={i} required={reqdFlag}><option value="">Select..</option></select>
                            </div>)
            }else if(this.state.formObj.fields[i].type.indexOf('select')>=0){
                let options = [<option value="">Select</option>]
                let optionList = this.state.formObj.fields[i].type.split(":")[1].split(",")
                for(var j=0; j<optionList.length; j++){
                    options.push(<option value={optionList[j]}>{optionList[j]}</option>)
                }
                fields.push(<div className="mb-3">
                              <label className="form-label" for={this.state.formObj.fields[i].id}>{this.state.formObj.fields[i].label}</label>
                              <select className="form-select form-select-sm" id={this.state.formObj.fields[i].id} value={this.state.formObj.fields[i].value} onChange={this.handleChange} indx={i} required={reqdFlag}>{options}</select>
                            </div>)
            }else if(this.state.formObj.fields[i].type == 'text-area'){
                fields.push(<div className="mb-3">
                              <label className="form-label" for={this.state.formObj.fields[i].id}>{this.state.formObj.fields[i].label}</label>
                              <textarea className="form-control form-control-sm" id={this.state.formObj.fields[i].id} type={this.state.formObj.fields[i].type} value={this.state.formObj.fields[i].value} onChange={this.handleChange} rows="2" indx={i} required={reqdFlag}></textarea>
                            </div>)
            }else if(this.state.formObj.fields[i].type == 'html'){
                fields.push(<div className="mb-3">
                              <label className="form-label" for={this.state.formObj.fields[i].id}>{this.state.formObj.fields[i].label}</label>
                              <input className="form-control form-control-sm htmlEditor4" id={this.state.formObj.fields[i].id} type={this.state.formObj.fields[i].type} value={this.state.formObj.fields[i].value} onChange={this.handleChange} indx={i} required={reqdFlag}/>
                            </div>)
            }else if(this.state.formObj.fields[i].type == 'file'){
                var pickerObj = {ssid: this.state.formObj.ssid,
                                email: this.state.formObj.email,
                                sht: this.state.formObj.sht,
                                filenameField: this.state.formObj.fields[i].id+"nameStr",
                                urlField: this.state.formObj.fields[i].id,
                                idField: this.state.formObj.fields[i].id+"idStr",
                                destination: this.state.formObj.fields[i].label,
                                destinationFolder: this.state.formObj.fields[i].label,
                                submitWithForm: true,
                                buttonTitle: "Attach Files",
                                by: this.state.formObj.by,
                                urlStr: ""}
                fields.push(<div className="mb-3">
                                <label className="form-label" for={this.state.formObj.fields[i].id}>{this.state.formObj.fields[i].label}</label>
                                <Picker obj={pickerObj}/>
                                <p id={this.state.formObj.fields[i].id+"nameStr"}></p>
                                <input type="hidden" id={this.state.formObj.fields[i].id} value={this.state.formObj.fields[i].value} onChange={this.handleChange} indx={i}/>
                                <input type="hidden" id={this.state.formObj.fields[i].id+"idStr"} value={this.state.formObj.fields[i].value} onChange={this.handleChange} indx={i}/>
                            </div>)                              
            }else{
                fields.push(<div className="mb-3">
                              <label className="form-label" for={this.state.formObj.fields[i].id}>{this.state.formObj.fields[i].label}</label>
                              <input className="form-control form-control-sm" id={this.state.formObj.fields[i].id} type={this.state.formObj.fields[i].type} value={this.state.formObj.fields[i].value} onChange={this.handleChange} indx={i} required={reqdFlag}/>
                            </div>)    
            }
        }
        var btn = <div className="btn-div">
                    <button className={this.props.formObj.btn.className} >
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="false" id="formBtnSpinner" style={{display: "none"}}></span>
                      {this.props.formObj.btn.label}
                    </button> 
                    <div className="alert alert-success collapse" role="alert" id="form-success-alert">
                      <p className="mb-0 flex-1">Update saved successfully.</p>
                    </div>
                    <div className="alert alert-danger collapse" role="alert" id="form-danger-alert">
                      <p className="mb-0 flex-1">Something went wrong !</p>
                    </div>
                  </div>
                  
        return <div id="dataForm"><form onSubmit={this.handleSubmit}>{fields}{btn}</form></div>
    }
}

export default Form