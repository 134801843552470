import React from "react";
import ReactDOM from 'react-dom';
import DropdownButton from '../components/DropdownButton';
import $ from 'jquery';

//import DataTable from 'datatables.net';

//$.DataTable = DataTable

class Table extends React.Component{
    constructor(props) {
        super(props)
        console.log(this.props)
        this.handleRowClick = this.handleRowClick.bind(this);
    }
    
    handleRowClick(event){
        if(this.props.obj.link == '#'){return}
        if(event.target.getAttribute('href').length>0){
            window.open(event.target.getAttribute('href'))
        }
    }
    
    componentDidMount() {
        //$('.table').DataTable();
    }
    
    render(){
    var rowNum = this.props.obj.json.length
    var colNum = this.props.obj.headers.length
    var rows = []
    var header = []
    for(var i=0; i<rowNum; i++){
        var cols = []
        for(var j=0; j<colNum; j++){
            var first_key = this.props.obj.headers[0]
            if(this.props.obj.flags[j]=="Y" || this.props.obj.flags[j]=="ALL"){ //&& !!this.props.obj.json[i][first_key] 
                var key = this.props.obj.headers[j]
                if(i==0){
                    header.push(<th>{key}</th>)
                }
                var link = this.props.obj.link ? this.props.obj.link + "?num=" + this.props.obj.json[i]['RowNum'] : this.props.obj.json[i]['Link']
                if(typeof(this.props.obj.json[i][key])=='number'){
                    cols.push(<td href={link}>{this.props.obj.json[i][key].toFixed(0)}</td>)
                }else{
                    cols.push(<td href={link}>{this.props.obj.json[i][key]}</td>)
                }
            }
        }
        if(!!this.props.obj.manageButton){
            var dropdownObj = {
                className: this.props.obj.manageButton.className,
                btnText: this.props.obj.manageButton.btnText,
                rowNum: this.props.obj.json[i]['RowNum'],
                options: this.props.obj.manageButton.options,
                snum: i
            }
            if(i==0){
                header.push(<th>{this.props.obj.manageButton.headerLabel}</th>)
            }
            cols.push(<td><DropdownButton obj={dropdownObj} /></td>)
        }
        rows.push(<tr className="border-bottom border-200"  onClick={this.handleRowClick}>{cols}</tr>)
    }

    return  <table className="table mb-0 fs--1 border-200 table-borderless"> 
                <thead className="bg-light"><tr>{header}</tr></thead>
                <tbody>{rows}</tbody>
            </table>
    }
}

export default Table