import React from "react";
import ReactDOM from 'react-dom';

class CustomButton extends React.Component{
    constructor(props) {
        super(props)
        //console.log(this.props)
        this.state = {obj: this.props.obj}
        //this.handleChange = this.handleChange.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    render(){
        return <button className={this.props.obj.className} onClick={window[this.props.obj.clickfunction]} id={this.props.obj.id}>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="false" id="formBtnSpinner" style={{display: "none"}}></span>
                    {this.props.obj.label}
                  </button> //type="submit" value="submit"
    }
}

export default CustomButton