import React from "react";
import ReactDOM from 'react-dom';
import $ from 'jquery';

class Timeline1 extends React.Component{
    constructor(props) {
        super(props)
        //console.log(this.props)
        this.handleRowClick = this.handleRowClick.bind(this);
    }
    
    handleRowClick(event){
        if(event.target.getAttribute('href').length>0){
            window.open(event.target.getAttribute('href'))
        }
    }
    
    render(){
    var rowNum = this.props.obj.arr.length
    //var colNum = this.props.obj.headers.length
    var items = []
    //var header = []
    for(var i=0; i<rowNum; i++){
        var by_str = !!this.props.obj.arr[i]["by"] ? ' by ' + this.props.obj.arr[i]["by"] : ""
        var for_str = !!this.props.obj.arr[i]["donefor"] ? ' for ' + this.props.obj.arr[i]["donefor"] : ""
        //var emoji = this.props.obj.arr[i]['emoji'] ||= '&#65039;'
        items.push(<a class="border-bottom-0 notification rounded-0 border-x-0 border border-300" href={this.props.obj.arr[i]["link"]}>
                <div class="notification-avatar">
                  <div class="avatar avatar-xl me-3">
                    <div class="avatar-emoji rounded-circle "><span role="img" aria-label="Emoji">{this.props.obj.arr[i]["emoji"]}</span></div>
                  </div>
                </div>
                <div class="notification-body">
                  <p class="mb-1"><strong>{this.props.obj.arr[i]["action"]} {for_str}</strong>{by_str}</p>
                  <span class="notification-time">On {this.props.obj.arr[i]["created_at"]}</span>
                </div>
              </a>)
    }

    return  <div>{items}</div>
    }
}

export default Timeline1