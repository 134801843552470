import React from "react";
import ReactDOM from 'react-dom';
import Dropzone from 'react-dropzone';
import $ from 'jquery';

class NewForm extends React.Component{
    constructor(props) {
        super(props)
        this.state = {formObj: this.props.formObj , checkboxValues: []}
        console.log("FormObj: ",this.state.formObj)
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onDrop = (files,indx) => {
          console.log(files)
          console.log(indx)
          var filesarr = []
          files.forEach(function(file){
             console.log(file)
             filesarr.push(file)
          })
          this.setState({fileObj: {files: filesarr}})
          this.state.formObj['fields'][indx]['value'] = {files: filesarr}
        };
        this.state.fileObj = {
          files: []
        };
    }
    
    handleChange(event) {
        console.log("In handleChange")
        console.log(event)
        var indx = event.target.getAttribute('indx')
        let obj = this.state.formObj
        console.log("event.target.value in handleChange: ", event.target.value)
        obj['fields'][indx]['value'] = event.target.value
        for(var i=0; i<obj['fields'].length; i++){
          if(obj['fields'][i]['type']=='disabled'){
            obj['fields'][i]['value'] = document.getElementById(obj['fields'][i]['key']).value
          }
        }
        this.setState({ formObj : obj })
    }
    
    
    handleSubmit(event) {
        event.preventDefault()
        $('#formBtnSpinner').show()
        $('button').prop('disabled', true)
        
        let obj = this.state.formObj
        let formFields = obj.fields
        var post_obj = {}
        for(var i=0;i<formFields.length;i++){
          console.log(formFields[i]['type'])
            if(formFields[i]['type']=='file' && !!formFields[i]['value'] ){
             var files = formFields[i]['value']['files']
              var fileInfoArr= []
              for(var j=0; j<files.length; j++){
                var formData = new FormData()
                formData.append('file', files[j])
                const req_url = "/api/v1/upload";
                const request = new XMLHttpRequest();
              
                request.open("POST", req_url, false);
                request.onreadystatechange = () => {
                  if (request.readyState === 4 && request.status === 200) {
                    fileInfoArr.push(JSON.parse(request.responseText))
                  }
                };
                request.send(formData);
              }
              formFields[i]['value'] = fileInfoArr
              post_obj[formFields[i]['key']] = formFields[i]['value']
            }else if(formFields[i]['type']=='html'){
              formFields[i]['value']=CKEDITOR.instances[formFields[i]['key']].getData()
              post_obj[formFields[i]['key']] = formFields[i]['value']
            }else{
              post_obj[formFields[i]['key']] = formFields[i]['value']
            }
        }
        
        post_obj['solution_id'] = obj.solution_id
        post_obj['company_id'] = obj.company_id
        post_obj['user_id'] = obj.user_id
        post_obj['docname'] = obj.docname
        post_obj['doctype'] = obj.doctype
        console.log(post_obj)
        console.log(JSON.stringify(post_obj))

        if(!obj.newEntry && !!obj.oid){
          var req_url = '/api/v1/update_data'
          var req_data = JSON.stringify({obj: post_obj, id: obj.oid})
        }else{
          var req_url = '/api/v1/submit_data'
          var req_data = JSON.stringify({obj: post_obj})
        }
        console.log(req_data)
        $.ajax({
          type: "POST",
          url: req_url,
          data: req_data,
          datatype : "application/json",
          contentType: "application/json; charset=utf-8",
          success: function(data){
            console.log(data)
            if(!!obj.action){
                record_user_action(obj.action,obj.by,obj.donefor,obj.link,obj.details,data['doc_id']['$oid'])
              }
              $('button').prop('disabled', false)
              if(obj.nextUrl == "refresh"){
                  window.location.reload(true)    
              }else if(!!obj.nextUrl){
                  window.location.href = obj.nextUrl + "?oid=" + data['doc_id']['$oid']
              }else{
                  $(event.target).find('.alert-success').show();
                  $('#formBtnSpinner').hide()
                  setTimeout(function(){
                    $(event.target).find('.alert-success').hide();
                  },2000)
              }
          },
          error: function(err){
            console.log(err);
            $(event.target).find('.alert-danger').show();
            setTimeout(function(){
                window.location.reload(true)  
            },1000)
          }
        });
    }
    
    render(){
        const container = {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '10px',
          borderWidth: 2,
          borderRadius: 2,
          borderColor: '#eeeeee',
          borderStyle: 'dashed',
          backgroundColor: '#fafafa',
          color: '#bdbdbd',
          outline: 'none',
          transition: 'border .24s ease-in-out',
          justifyContent: 'center'
        }
        
        const files = this.state.fileObj.files.map(file => (
          <li key={file.name}>
            {file.name} - {file.size} bytes
          </li>
        ));
        
        var formLen = this.state.formObj.fields.length
        var formFieldsObj = this.state.formObj.fields
        var fields = []
        var requiredFields = this.state.formObj.requiredFields
        for(var i=0; i<formLen; i++){
            var reqdFlag = (!!this.state.formObj.fields[i].required || requiredFields.indexOf(this.state.formObj.fields[i].label) >=0) ? true : false
            //console.log(this.state.formObj.fields[i])
            if(this.state.formObj.fields[i].type=='hidden'){
                fields.push(<div className="mb-3">
                              <input className="form-control form-control-sm" id={this.state.formObj.fields[i].key} type={this.state.formObj.fields[i].type} value={this.state.formObj.fields[i].value} onChange={this.handleChange} indx={i} />
                            </div>)    
            }else if(this.state.formObj.fields[i].type == 'disabled'){
                fields.push(<div className="mb-3">
                              <label className="form-label" for={this.state.formObj.fields[i].key}>{this.state.formObj.fields[i].label}</label>
                              <input className="form-control form-control-sm" id={this.state.formObj.fields[i].key} type="text" value={this.state.formObj.fields[i].value} indx={i} disabled={true}/>
                            </div>)  
            }else if(this.state.formObj.fields[i].type.indexOf('multiselect')>=0){
                fields.push(<div className="mb-3">
                              <input type="hidden" id={this.state.formObj.fields[i].key+"-options"} value={this.state.formObj.fields[i].options} />
                              <label className="form-label" for={this.state.formObj.fields[i].key}>{this.state.formObj.fields[i].label}</label>
                              <select className="form-select js-choice" id={this.state.formObj.fields[i].key} value={this.state.formObj.fields[i].value} onChange={this.handleChange} indx={i} required={reqdFlag}><option value="">Select..</option></select>
                            </div>)
            }else if(this.state.formObj.fields[i].type.indexOf('select')>=0){
                let options = [<option value="">Select</option>]
                let optionList = this.state.formObj.fields[i].options
                for(var j=0; j<optionList.length; j++){
                    options.push(<option value={optionList[j].value}>{optionList[j].text}</option>)
                }
                fields.push(<div className="mb-3">
                              <label className="form-label" for={this.state.formObj.fields[i].key}>{this.state.formObj.fields[i].label}</label>
                              <select className="form-select form-select-sm" id={this.state.formObj.fields[i].key} value={this.state.formObj.fields[i].value} onChange={this.handleChange} indx={i} required={reqdFlag}>{options}</select>
                            </div>)
            }
            else if(this.state.formObj.fields[i].type.indexOf('radio')>=0){
                let options = [<div className="form-check form-check-inline"><input className="form-check-input" type="radio" name="radioBtn" value="Select"/> <label className="form-check-label" htmlFor="Select">Select</label></div>]
                let optionList = this.state.formObj.fields[i].options
                for(var j=0; j<optionList.length; j++){
                    if(this.state.formObj.fields[i].value === optionList[j].value){
                      options.push(<div className="form-check form-check-inline"><input className="form-check-input" type="radio" name="radioBtn" onChange={this.handleChange} value={optionList[j].value} indx={i} checked="checked" /> <label className="form-check-label" htmlFor={optionList[j].value}>{optionList[j].text}</label></div>)
                    }
                    else{
                      options.push(<div className="form-check form-check-inline"><input className="form-check-input" type="radio" name="radioBtn" onChange={this.handleChange} value={optionList[j].value} indx={i} /> <label className="form-check-label" htmlFor={optionList[j].value}>{optionList[j].text}</label></div>)
                    }
                }
                fields.push(<div className="mb-3">
                              <label className="form-label" for={this.state.formObj.fields[i].key}>{this.state.formObj.fields[i].label}</label>
                              <div id={this.state.formObj.fields[i].key} required={reqdFlag}>{options}</div>
                            </div>)
            }
            else if(this.state.formObj.fields[i].type.indexOf('checkbox')>=0){
                let eValuesArr = []
                if("value" in this.state.formObj.fields[i]){
                  let eValues = this.state.formObj.fields[i].value
                  if(eValues!=null){
                    if(eValues.indexOf(',') > -1){
                      eValuesArr = eValues.split(",")
                    }
                    else{
                      eValuesArr = eValues.split(" ")
                      
                    }
                  }
                  
                }
                
                let valArr = this.state.checkboxValues
                for(var k=0; k<eValuesArr.length; k++){
                  if(!valArr.includes(eValuesArr[k])){
                    valArr.push(eValuesArr[k])
                  }
                }
                
                let values = []
                let options = []
                let optionList = this.state.formObj.fields[i].options
                for(var j=0; j<optionList.length; j++){
                    if(eValuesArr.includes(optionList[j].value) === false){
                      options.push(<div className="form-check form-check-inline"><input className="form-check-input" type="checkbox" onChange={()=>{
                        if(event.target.checked===true){
                          valArr.push(event.target.value)
                        }
                        else{
                          var index = valArr.indexOf(event.target.value);
                          valArr.splice(index, 1);
                        }
                        this.setState({ checkboxValues : valArr })
                        event.target.value = this.state.checkboxValues.toString()
                        var indx = event.target.getAttribute('indx')
                        let obj = this.state.formObj
                        obj['fields'][indx]['value'] = event.target.value
                        for(var i=0; i<obj['fields'].length; i++){
                          if(obj['fields'][i]['type']=='disabled'){
                            obj['fields'][i]['value'] = document.getElementById(obj['fields'][i]['key']).value
                          }
                        }
                        this.setState({ formObj : obj })
                        console.log("formObj: ",this.state.formObj)
                      }} value={optionList[j].value} indx={i} /> <label className="form-check-label" htmlFor={optionList[j].value}>{optionList[j].text}</label></div>)
                    }
                    else{
                      options.push(<div className="form-check form-check-inline"><input className="form-check-input" type="checkbox" checked="checked" value={optionList[j].value} indx={i} onChange={()=>{
                        if(event.target.checked===true){
                          valArr.push(event.target.value)
                        }
                        else{
                          var index = valArr.indexOf(event.target.value);
                          valArr.splice(index, 1);
                        }
                        this.setState({ checkboxValues : valArr })
                        console.log("Values:", this.state.checkboxValues)
                        event.target.value = this.state.checkboxValues.toString()
                        var indx = event.target.getAttribute('indx')
                        let obj = this.state.formObj
                        console.log("event.target.value in handleChange: ", event.target.value)
                        obj['fields'][indx]['value'] = event.target.value
                        for(var i=0; i<obj['fields'].length; i++){
                          if(obj['fields'][i]['type']=='disabled'){
                            obj['fields'][i]['value'] = document.getElementById(obj['fields'][i]['key']).value
                          }
                        }
                        this.setState({ formObj : obj })
                        console.log("formObj: ",this.state.formObj)
                      }} /> <label className="form-check-label" htmlFor={optionList[j].value}>{optionList[j].text}</label></div>)
                    }
                }
                fields.push(<div className="mb-3">
                              <label className="form-label" for={this.state.formObj.fields[i].key}>{this.state.formObj.fields[i].label}</label>
                              <div id={this.state.formObj.fields[i].key} required={reqdFlag}>{options}</div>
                            </div>)
            }
            else if(this.state.formObj.fields[i].type == 'text-area'){
                fields.push(<div className="mb-3">
                              <label className="form-label" for={this.state.formObj.fields[i].key}>{this.state.formObj.fields[i].label}</label>
                              <textarea className="form-control form-control-sm" id={this.state.formObj.fields[i].key} type={this.state.formObj.fields[i].type} value={this.state.formObj.fields[i].value} onChange={this.handleChange} rows="2" indx={i} required={reqdFlag}></textarea>
                            </div>)
            }else if(this.state.formObj.fields[i].type == 'html'){
                fields.push(<div className="mb-3">
                              <label className="form-label" for={this.state.formObj.fields[i].key}>{this.state.formObj.fields[i].label}</label>
                              <input className="form-control form-control-sm htmlEditor4" id={this.state.formObj.fields[i].key} type={this.state.formObj.fields[i].type} value={this.state.formObj.fields[i].value} onChange={this.handleChange} indx={i} required={reqdFlag}/>
                            </div>)
            }else if(this.state.formObj.fields[i].type == 'file'){
                var index = i
                fields.push(<div className="mb-3">
                              <label className="form-label" for={this.state.formObj.fields[i].key}>{this.state.formObj.fields[i].label}</label>
                              <Dropzone onDrop={(e) => this.onDrop(e,index)} id={this.state.formObj.fields[i].key} indx={i}>
                                {({getRootProps, getInputProps}) => (
                                  <section className="container" style={container}>
                                    <div {...getRootProps({className: 'dropzone'})} >
                                      <input {...getInputProps()} />
                                      <p>Drag 'n' drop some files here, or click to select files</p>
                                    </div>
                                    <aside>
                                      <ul>{files}</ul>
                                    </aside>
                                  </section>
                                )}
                              </Dropzone>
                            </div>)                               
            }else{
                fields.push(<div className="mb-3">
                              <label className="form-label" for={this.state.formObj.fields[i].key}>{this.state.formObj.fields[i].label}</label>
                              <input className="form-control form-control-sm" id={this.state.formObj.fields[i].key} type={this.state.formObj.fields[i].type} value={this.state.formObj.fields[i].value} onChange={this.handleChange} indx={i} required={reqdFlag}/>
                            </div>)    
            }
        }
        var btn = <div className="btn-div">
                    <button className={this.props.formObj.btn.className} >
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="false" id="formBtnSpinner" style={{display: "none"}}></span>
                      {this.props.formObj.btn.label}
                    </button> 
                    <div className="alert alert-success collapse" role="alert" id="form-success-alert">
                      <p className="mb-0 flex-1">Update saved successfully.</p>
                    </div>
                    <div className="alert alert-danger collapse" role="alert" id="form-danger-alert">
                      <p className="mb-0 flex-1">Something went wrong !</p>
                    </div>
                  </div>
                  
        return <div id="dataForm"><form onSubmit={this.handleSubmit}>{fields}{btn}</form></div>
    }
}

export default NewForm
