import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import Dropzone from 'react-dropzone';
import $ from 'jquery';

const FormWithTable =({formObj})=>{
  
  
  // Data Expected
  // formobj = {
  //       name: '',
  //       id: '',
  //       newEntry: true,
  //       fields: [{label: '', key: '', type: ''},
  //               {label: '', key: '', type: ''},
  //               {label: '', key: '', type: ''},
  //               {label: '', key: '', type: ''},
  //               {label: '', key: '', type: ''},
  //               {label: '', key: '', type: ''},
  //               {label: '', key: '', type: ''},
  //               {label: '', key: '', type: 'select', options: [{text: '', value: ''}, {text: '', value: ''}, {text: '', value: ''},{text: '', value: ''}]},
                
                
                
  //               {label: ' ', 
  //               key: '', 
  //               type: 'table',
  //               headers:[{label: '', key: '', type: '', values:[]},
  //                           {label: '', key: '', type: '', values:[]},
  //                           {label: '', key: '', type: '', values:[]},
  //                           {label: '', key: '', type: 'select', values:[],options: [{text: '', value: ''}, {text: '', value: ''}, {text: '', value: ''},{text: '', value: ''}]}]
  //               }],
  
                  
        // If type table complete headers array will be saved corresponding to the key ob the object
  


  
  //       btn: {label: '', action:'submit', className: 'btn btn-primary me-1 mb-1 col-md-3 m-2'},
  //       requiredFields: [],
  //       nextUrl: 'refresh',
  //       solution_id: @solution.id,
  //       company_id: current_company.id,
  //       user_id: 1153,
  //       docname: '',
  //       doctype: '',
  //       action: '',
  //       by: '',
  //       donefor: '',
  //       link: ''
  //   } 
  

  
  let {name,id,newEntry,oid,fields,btn,requiredFields,nextUrl,solution_id,company_id,user_id,docname,doctype,action,by,donefor,link} = formObj
  const [formObject,setFormObject] = useState(formObj)
  const [fieldsArr,setFieldsArr] = useState(fields)
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [selectedIndx, setSelectedIndx] = useState(null);
  const [fileObj, setFileObj] = useState({ files: [] });


  const onDrop = files => {
    let obj = { ...formObject };
    let filesarr = [];
    files.forEach(function (file) {
      console.log(file);
      filesarr.push(file);
    });
    //this.setState({fileObj: {files: filesarr}})
    obj['fields'][selectedIndx]['value'] = { files: filesarr };

    obj['fields'][selectedIndx]['fileDisplay'] = files.map(file => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ));
    setFormObject(obj);
  };
  
 
  const handleClick = event => {
    setSelectedIndx(event.target.parentElement.parentElement.parentElement.getAttribute('indx'));
  };

  const handleChange = event => {

    const indx = event.target.getAttribute('indx');
    const newObj = { ...formObject };
    newObj.fields[indx].value = event.target.value;
    for (let i = 0; i < newObj.fields.length; i++) {
      if (newObj.fields[i].type === 'disabled') {
        newObj.fields[i].value = document.getElementById(newObj.fields[i].key).value;
      }
    }
    setFormObject(newObj);  
    
  };
 
  
  const handleSubmit = event => {
        event.preventDefault()
        $('#formBtnSpinner').show()
        $('button').prop('disabled', true)
        
        let obj = formObject
        let formFields = obj.fields
        let post_obj = {}
        let requestsObj = []
        for(let i=0;i<formFields.length;i++){
          console.log(formFields[i]['type'])
            if(formFields[i]['type']=='file' && !!formFields[i]['value'] && !!formFields[i]['value']['files'] ){
             let files = formFields[i]['value']['files']
              let fileInfoArr= []
              for(let j=0; j<files.length; j++){
                let formData = new FormData()
                formData.append('file', files[j])
                const req_url = "/api/v1/upload";
                const request = new XMLHttpRequest();
              
                request.open("POST", req_url, false);
                request.onreadystatechange = () => {
                  if (request.readyState === 4 && request.status === 200) {
                    let parsedResponse = JSON.parse(request.responseText)
                    fileInfoArr.push(parsedResponse)
                    
                    let filePostObj = {
                      docname:'Solution Images',
                      doctype: 'File',
                      solution_id: obj.solution_id,
                      company_id: obj.company_id,
                      user_id: obj.user_id,
                      solution_template_id: obj.solution_template_id ? obj.solution_template_id : null,
                      api_request_source: 'JS',
                      ...parsedResponse
                    }
                    requestsObj.push(filePostObj)
                  }
                };
                request.send(formData);
              }
              formFields[i]['value'] = fileInfoArr
              post_obj[formFields[i]['key']] = formFields[i]['value']
            }else if(formFields[i]['type']=='html'){
              formFields[i]['value']=CKEDITOR.instances[formFields[i]['key']].getData()
              post_obj[formFields[i]['key']] = formFields[i]['value']
            }else if(formFields[i]['type']=='table'){
              // post_obj[formFields[i]['key']] = formFields[i]['headers']
              for(let j = 0; j<formFields[i]['headers'].length; j++){
                post_obj[formFields[i]['headers'][j]['key']]= formFields[i]['headers'][j]['values']
              }
            }else{
              post_obj[formFields[i]['key']] = formFields[i]['value']
            }
        }
        
        post_obj['solution_id'] = obj.solution_id
        post_obj['company_id'] = obj.company_id
        post_obj['user_id'] = obj.user_id
        post_obj['docname'] = obj.docname
        post_obj['doctype'] = obj.doctype
        console.log(post_obj)
        console.log(JSON.stringify(post_obj))
        
        let req_url = ''
        let req_data = ''
        if(!obj.newEntry && !!obj.oid){
          let update_obj = {obj: post_obj, id: obj.oid}
            
          if(formObj.user_email){
              update_obj['user'] = formObj.user_email
          }
          
          if(formObj.key){
              update_obj['key'] = formObj.key
          }
          req_url = '/api/v1/update_data'
          req_data = JSON.stringify(update_obj)
        }else{
          let submit_obj = {obj: post_obj}
            
          if(formObj.user_email){
              submit_obj['user'] = formObj.user_email
          }
          
          if(formObj.key){
              submit_obj['key'] = formObj.key
          }
          req_url = '/api/v1/submit_data'
          req_data = JSON.stringify(submit_obj)
        }
        console.log(req_data)
        $.ajax({
          type: "POST",
          url: req_url,
          data: req_data,
          datatype : "application/json",
          contentType: "application/json; charset=utf-8",
          success: function(data){
            console.log(data)
            if(!!obj.action){
              record_user_action(obj.action,obj.by,obj.donefor,obj.link,obj.details,data['doc_id']['$oid'])
            }
            
            if(requestsObj && requestsObj.length > 0){
              let totalSumbissions = 0
              requestsObj.forEach(o=>{
                let file_req_url = '/api/v1/submit_data'
                o['doc_id'] = data['doc_id']['$oid']
                
                let submit_obj = {obj: o}
            
                if(formObj.user_email){
                    submit_obj['user'] = formObj.user_email
                }
                
                if(formObj.key){
                    submit_obj['key'] = formObj.key
                }
                
                let file_req_data = JSON.stringify(submit_obj)
                $.ajax({
                  type: "POST",
                  url: file_req_url,
                  data: file_req_data,
                  datatype : "application/json",
                  contentType: "application/json; charset=utf-8",
                  success: function(data){
                    totalSumbissions++
                    if(totalSumbissions == requestsObj.length){
                      $('button').prop('disabled', false)
                      if(obj.nextUrl == "refresh"){
                          window.location.reload(true)    
                      }else if(!!obj.nextUrl){
                          window.location.href = obj.nextUrl + "?oid=" + data['doc_id']['$oid']
                      }else{
                          $(event.target).find('.alert-success').show();
                          $('#formBtnSpinner').hide()
                          setTimeout(function(){
                            $(event.target).find('.alert-success').hide();
                          },2000)
                      }
                    }
                  },
                  error: function(data){
                    totalSumbissions++
                    if(totalSumbissions == requestsObj.length){
                      $('button').prop('disabled', false)
                      if(obj.nextUrl == "refresh"){
                          window.location.reload(true)    
                      }else if(!!obj.nextUrl){
                          window.location.href = obj.nextUrl + "?oid=" + data['doc_id']['$oid']
                      }else{
                          $(event.target).find('.alert-success').show();
                          $('#formBtnSpinner').hide()
                          setTimeout(function(){
                            $(event.target).find('.alert-success').hide();
                          },2000)
                      }
                    }
                  },
                })
              })
            }else{
              $('button').prop('disabled', false)
              if(obj.nextUrl == "refresh"){
                  window.location.reload(true)    
              }else if(!!obj.nextUrl){
                  window.location.href = obj.nextUrl + "?oid=" + data['doc_id']['$oid']
              }else{
                  $(event.target).find('.alert-success').show();
                  $('#formBtnSpinner').hide()
                  setTimeout(function(){
                    $(event.target).find('.alert-success').hide();
                  },2000)
              }
            }
            
            
             
          },
          error: function(err){
            console.log(err);
            $(event.target).find('.alert-danger').show();
            setTimeout(function(){
                window.location.reload(true)  
            },1000)
          }
        });
  }
  
  const container = {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '10px',
          borderWidth: 2,
          borderRadius: 2,
          borderColor: '#eeeeee',
          borderStyle: 'dashed',
          backgroundColor: '#fafafa',
          color: '#bdbdbd',
          outline: 'none',
          transition: 'border .24s ease-in-out',
          justifyContent: 'center'
        }
        let formLen = fieldsArr.length
        let formFieldsObj = fieldsArr
        let newFields = []
        
        for(let i=0; i<formLen; i++){
            let reqdFlag = (fieldsArr[i].required || requiredFields.indexOf(fieldsArr[i].label) >=0) ? true : false
            //console.log(fieldsArr[i])
            if(fieldsArr[i].type=='hidden'){
                newFields.push(<div className="mb-3">
                              <input className="form-control form-control-sm" id={fieldsArr[i].key} type={fieldsArr[i].type} value={fieldsArr[i].value} onChange={(e)=>handleChange(e)} indx={i} />
                            </div>)    
            }else if(fieldsArr[i].type == 'disabled'){
                newFields.push(<div className="mb-3">
                              <label className="form-label" for={fieldsArr[i].key}>{fieldsArr[i].label}</label>
                              <input className="form-control form-control-sm" id={fieldsArr[i].key} type="text" value={fieldsArr[i].value} indx={i} disabled={true}/>
                            </div>)  
            }else if(fieldsArr[i].type.indexOf('multiselect')>=0){
                newFields.push(<div className="mb-3">
                              <input type="hidden" id={fieldsArr[i].key+"-options"} value={fieldsArr[i].options} />
                              <label className="form-label" for={fieldsArr[i].key}>{fieldsArr[i].label}</label>
                              <select className="form-select js-choice" id={fieldsArr[i].key} value={fieldsArr[i].value} onChange={(e)=>handleChange(e)} indx={i} required={reqdFlag}><option value="">Select..</option></select>
                            </div>)
            }else if(fieldsArr[i].type.indexOf('select')>=0){
                let options = [<option value="">Select</option>]
                let optionList = fieldsArr[i].options
                for(let j=0; j<optionList.length; j++){
                    options.push(<option value={optionList[j].value}>{optionList[j].text}</option>)
                }
                newFields.push(<div className="mb-3">
                              <label className="form-label" for={fieldsArr[i].key}>{fieldsArr[i].label}</label>
                              <select className="form-select form-select-sm" id={fieldsArr[i].key} value={fieldsArr[i].value} onChange={(e)=>handleChange(e)} indx={i} required={reqdFlag}>{options}</select>
                            </div>)
            }else if(fieldsArr[i].type.indexOf('radio')>=0){
                // let options = [<div className="form-check form-check-inline"><input className="form-check-input" type="radio" name="radioBtn" value="Select"/> <label className="form-check-label" htmlFor="Select">Select</label></div>]
                let options = []
                let optionList = fieldsArr[i].options
                for(let j=0; j<optionList.length; j++){
                    if(fieldsArr[i].value === optionList[j].value){
                      options.push(<div className="form-check form-check-inline"><input className="form-check-input" type="radio" name={`radioBtn${i}`} onChange={(e)=>handleChange(e)} value={optionList[j].value} indx={i} checked="checked" /> <label className="form-check-label" htmlFor={`radioLabel${i}`}>{optionList[j].text}</label></div>)
                    }
                    else{
                      options.push(<div className="form-check form-check-inline"><input className="form-check-input" type="radio" name={`radioBtn${i}`} onChange={(e)=>handleChange(e)} value={optionList[j].value} indx={i} /> <label className="form-check-label" htmlFor={`radioLabel${i}`}>{optionList[j].text}</label></div>)
                    }
                }
                newFields.push(<div className="mb-3">
                              <label className="form-label" for={fieldsArr[i].key}>{fieldsArr[i].label}</label>
                              <div id={fieldsArr[i].key} required={reqdFlag}>{options}</div>
                            </div>)
            }else if(fieldsArr[i].type.indexOf('checkbox')>=0){
              console.log('checkbox')
              console.log(fieldsArr[i])
              let eValuesArr = []
                if("value" in fieldsArr[i]){
                  let eValues = fieldsArr[i].value
                  if(eValues!=null){
                    if(eValues.indexOf(',') > -1){
                      eValuesArr = eValues.split(",")
                    }
                    else{
                      eValuesArr = eValues.split(" ")
                    }
                  }
                }

                let values = fieldsArr[i].value?fieldsArr[i].value.split(","):[]
                let options = []
                let optionList = fieldsArr[i].options
                // console.log(optionList)
                for(var j=0; j<optionList.length; j++){
                    if(eValuesArr.includes(optionList[j].value) === false){
                      options.push(<div className="form-check form-check-inline"><input className="form-check-input" type="checkbox" onChange={()=>{
                        if(event.target.checked===true){
                          values.push(event.target.value)
 
                        }
                        else{
                          var index = values.indexOf(event.target.value);
                          values.splice(index, 1);
                        }

                        event.target.value = values.toString()
                        var indx = event.target.getAttribute('indx')
                        let obj = formObject
                        obj['fields'][indx]['value'] = event.target.value
                        for(var i=0; i<obj['fields'].length; i++){
                          if(obj['fields'][i]['type']=='disabled'){
                            obj['fields'][i]['value'] = document.getElementById(obj['fields'][i]['key']).value
                          }
                        }
                        setFormObject(obj)
                        console.log("formObj: ",formObject)
                      }} value={optionList[j].value} indx={i} /> <label className="form-check-label" htmlFor={optionList[j].value}>{optionList[j].text}</label></div>)
                    }
                    else{
                      options.push(<div className="form-check form-check-inline"><input className="form-check-input" type="checkbox" checked="checked" value={optionList[j].value} indx={i} onChange={()=>{
                        if(event.target.checked===true){
                          values.push(event.target.value)
                        }
                        else{
                          var index = values.indexOf(event.target.value);
                          values.splice(index, 1);

                        }
                        event.target.value = values.toString()
                        var indx = event.target.getAttribute('indx')
                        let obj = formObject
                        obj['fields'][indx]['value'] = event.target.value
                        for(var i=0; i<obj['fields'].length; i++){
                          if(obj['fields'][i]['type']=='disabled'){
                            obj['fields'][i]['value'] = document.getElementById(obj['fields'][i]['key']).value
                          }
                        }
                        setFormObject(obj)
                        console.log("formObj: ",formObject)
                      }} /> <label className="form-check-label" htmlFor={optionList[j].value}>{optionList[j].text}</label></div>)
                    }
                }
                newFields.push(<div className="mb-3">
                              <label className="form-label" for={fieldsArr[i].key}>{fieldsArr[i].label}</label>
                              <div id={fieldsArr[i].key} required={reqdFlag}>{options}</div>
                            </div>)
             
            }else if(fieldsArr[i].type == 'text-area'){
                newFields.push(<div className="mb-3">
                              <label className="form-label" for={fieldsArr[i].key}>{fieldsArr[i].label}</label>
                              <textarea className="form-control form-control-sm" id={fieldsArr[i].key} type={fieldsArr[i].type} value={fieldsArr[i].value} onChange={(e)=>handleChange(e)} rows="2" indx={i} required={reqdFlag}></textarea>
                            </div>)
            }else if(fieldsArr[i].type == 'html'){
                newFields.push(<div className="mb-3">
                              <label className="form-label" for={fieldsArr[i].key}>{fieldsArr[i].label}</label>
                              <input className="form-control form-control-sm htmlEditor4" id={fieldsArr[i].key} type={fieldsArr[i].type} value={fieldsArr[i].value} onChange={(e)=>handleChange(e)} indx={i} required={reqdFlag}/>
                            </div>)
            }else if(fieldsArr[i].type == 'file'){
                let index = i
                let files = fieldsArr[i].fileDisplay
                newFields.push(<div className="mb-3" onClick={(e)=>handleClick(e)} indx={i}>
                              <label className="form-label" for={fieldsArr[i].key}>{fieldsArr[i].label}</label>
                              <Dropzone onDrop={(e) => onDrop(e,index)} id={fieldsArr[i].key} indx={i}>
                                {({getRootProps, getInputProps}) => (
                                  <section className="container" style={container}>
                                    <div {...getRootProps({className: 'dropzone'})} >
                                      <input {...getInputProps()} />
                                      <p>Drag 'n' drop some files here, or click to select files</p>
                                    </div>
                                    <aside>
                                      <ul>{files}</ul>
                                      
                                    
                                      </aside>
                                  </section>
                                )}
                              </Dropzone>
                            </div>)                               
            }else if(fieldsArr[i].type == 'table'){
                let headers = fieldsArr[i].headers
                let requiredFields = fieldsArr[i].requiredFields ? fieldsArr[i].requiredFields : []
                  
                  const [formValues, setFormValues] = useState([]);
                  const [tableHeaders, setTableHeaders] = useState(headers);
                
                  const handleInputChange = (headerIndex, event) => {
                    const newValue = event.target.value;
                    setFormValues((prevValues) => {
                      const updatedValues = [...prevValues];
                      updatedValues[headerIndex] = newValue;
                      return updatedValues;
                    });
                  };
                
                  const handleAddButtonClick = (e) => {
                    e.preventDefault()
                    // const entries = document.querySelectorAll('.entry')
                    const idsToCheck = requiredFields; 

                      let allValuesEntered = true;
                      
                      idsToCheck.forEach(id => {
                        const entry = document.getElementById(`${id}-entry`);
                        if (entry && !entry.value.trim()) {
                          allValuesEntered = false;
                        }
                      });
                      if(allValuesEntered){
                        const newEntry = {};
                        tableHeaders.forEach((header, index) => {
                          newEntry[header.key] = formValues[index];
                        });
                        setFormValues([]);
                        tableHeaders.forEach((header, index) => {
                          header.values.push(formValues[index]);
                        });
                      }else{
                        alert('Please fill all the required fields!')
                      }
                    
                    // console.log('New entry:', newEntry);
                    // console.log(tableHeaders);
                    // setFormObject()
                  };
                
                  const handleRowDelete = (e,rowIndex) => {
                    e.preventDefault()
                    setTableHeaders((prevHeaders) => {
                      const updatedHeaders = [...prevHeaders];
                      updatedHeaders.forEach((header) => {
                        header.values.splice(rowIndex, 1);
                      });
                      return updatedHeaders;
                    });
                    // console.log(tableHeaders);
                  };
                
                  newFields.push(
                    <table className="table table-striped table-hover table-responsive scrollbar text-sm text-center table-sm">
                      <thead className='bg-secondary text-white'>
                        <tr>
                          {tableHeaders.map((header) => (
                            <th scope="col" className='small' key={header.key}>
                              {header.label}
                              {requiredFields.includes(header.key) && '*'}
                            </th>
                          ))}
                          <th scope="col" className='small'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {tableHeaders.map((header, index) => (
                            <td key={header.key}>
                            
                             {header.type === 'select' ? (
                                <select
                                  className='form-select form-select-sm entry' 
                                  data-style="select-with-transition"
                                  value={formValues[index] || ''}
                                  onChange={(event) => handleInputChange(index, event)}
                                  id={`${header.key}-entry`}
                                >
                                  <option value="">
                                    Select
                                  </option>
                                  {header.options.map((option) => (
                                    <option key={option.value} value={option.value}>
                                      {option.text}
                                    </option>
                                  ))}
                                </select>
                              ) : header.type === 'disabled' ? (
                                  <input
                                    className="form-control form-control-sm"
                                    id={header.key}
                                    type="text"
                                    value={header.value}
                                    disabled={true}
                                    id={`${header.key}-entry`}
                                  />
                                
                              ) : header.type === 'text-area' ?(
                                <textarea 
                                  className="form-control form-control-sm entry" 
                                  id={`${header.key}-entry`}
                                  value={formValues[index] || ''}
                                  onChange={(event) => handleInputChange(index, event)} 
                                  rows="2">
                                </textarea>
                              ): (
                                <input
                                  className="form-control form-control-sm entry" 
                                  type={header.type}
                                  value={formValues[index] || ''}
                                  onChange={(event) => handleInputChange(index, event)}
                                  id={`${header.key}-entry`}
                                />
                              )}

                            </td>
                          ))}
                          <td>
                            <button className='btn btn-success btn-sm' onClick={(e)=>handleAddButtonClick(e)}>Add</button>
                          </td>
                        </tr>
                        {tableHeaders[0].values.map((_, rowIndex) => (
                          <tr key={rowIndex}>
                            {tableHeaders.map((header) => (
                              <td className='text-sm' key={header.key}>{header.values[rowIndex]}</td>
                            ))}
                            <td>
                              <button className="btn btn-link btn-sm" onClick={(e) => handleRowDelete(e,rowIndex)}><span className="fas fa-trash-alt"></span></button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  );
                }
            else{
                newFields.push(<div className="mb-3">
                              <label className="form-label" for={fieldsArr[i].key}>{fieldsArr[i].label}</label>
                              <input className="form-control form-control-sm" id={fieldsArr[i].key} type={fieldsArr[i].type} value={fieldsArr[i].value} onChange={(e)=>handleChange(e)} indx={i} required={reqdFlag}/>
                            </div>)    
            }
        }
         let btns = <div className="btn-div">
                    <button className={btn.className} >
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="false" id="formBtnSpinner" style={{display: "none"}}></span>
                      {btn.label}
                    </button> 
                    <div className="alert alert-success collapse" role="alert" id="form-success-alert">
                      <p className="mb-0 flex-1">Update saved successfully.</p>
                    </div>
                    <div className="alert alert-danger collapse" role="alert" id="form-danger-alert">
                      <p className="mb-0 flex-1">Something went wrong !</p>
                    </div>
                  </div>
                  
                  if(!formObject['oid'] && !formObject['newEntry'] ){
                    return  <div>
                              <p>Something Went Wrong!!</p>
                              <p>Incomplete Data To Add A Form</p>
                            </div>
                  }else{
                    return <div id="dataForm" className="rounded-2 p-2">
                            <div id={`formWithTableComponent-${id}`}>
                              <form onSubmit={(e)=>handleSubmit(e)}>{newFields}{btns}</form>
                            </div>
                          </div>
                  }
        
        
  
};
  
export default FormWithTable