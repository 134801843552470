import React, { useState, useEffect } from "react";
import DropdownButton1 from '../components/DropdownButton1';

const LazyLoadingTable = (props) => {
    const [arr, setArr] = useState(props.obj.arr);
    const [rowNum, setRowNum] = useState(arr.length);
    const [colNum, setColNum] = useState(props.obj.headers.length);
    const [rows, setRows] = useState([]);
    const [header, setHeader] = useState([]);
    
    const handleRowClick = (event) => {
        if (props.obj.link === '#') {
            return;
        }
        if (event.target.getAttribute('href').length > 0) {
            window.open(event.target.getAttribute('href'));
        }
    }
    async function loadTable() {
        await new Promise(r => setTimeout(r, 1000));
        const tables = document.querySelectorAll('.table');
    
        tables.forEach(table => {
            table.classList.add("hover");
            table.classList.add("stripe");
            table.classList.add("text-center");
            $(table).DataTable();
            table.parentElement.classList.add("overflow-auto");
            table.parentElement.classList.add("scrollbar");
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            $.ajax({
                type: "GET",
                url: `/api/v1/get_data?${props.obj.query}`,
                success: function(data){
                    if(props.obj.operation){
                        data = eval(props.obj.operation)
                    }
                    setArr(data); 
                    setRowNum(data.length); 
                    loadTable()
                    document.getElementById('loading-text').classList.add('d-none')
                },
                error: function(err){
                    console.log(err)
                }
            })
        };
        if(props.obj.query){
            fetchData();
        }
    }, [props.obj.query]);



    useEffect(() => {
        const newRows = [];
        const newHeader = [];

        for (let i = 0; i < rowNum; i++) {
            const cols = [];

            for (let j = 0; j < colNum; j++) {
                const key = props.obj.headers[j]['key'];
                const label = props.obj.headers[j]['label'];
                
                if (i === 0) {
                    newHeader.push(<th key={`header-${j}`}>{label}</th>);
                }
                
                const link = props.obj.link ? `${props.obj.link}?oid=${arr[i]["_id"]['$oid']}` : arr[i]['link'];
                if(key=='_id'){
                    
                    if (typeof(arr[i][key]['$oid']) === 'number') {
                        cols.push(<td key={`col-${i}-${j}`} href={link}>{arr[i][key]['$oid'].toFixed(2)}</td>);
                    } else {
                        cols.push(<td key={`col-${i}-${j}`} href={link}>{arr[i][key]['$oid']}</td>);
                    }
                }else{
                    
                    if (typeof(arr[i][key]) === 'number') {
                        cols.push(<td key={`col-${i}-${j}`} href={link}>{arr[i][key].toFixed(2)}</td>);
                    } else {
                        cols.push(<td key={`col-${i}-${j}`} href={link}>{arr[i][key]}</td>);
                    }
                }
            }

            if (!!props.obj.manageButton) {
                const dropdownObj = {
                    className: props.obj.manageButton.className,
                    btnText: props.obj.manageButton.btnText,
                    _id: arr[i]["_id"]['$oid'].toString(),
                    options: props.obj.manageButton.options,
                    snum: i
                };

                if (i === 0) {
                    newHeader.push(<th key={`manage-button-header`}>{props.obj.manageButton.headerLabel}</th>);
                }

                cols.push(
                    <td key={`manage-button-${i}`}>
                        <DropdownButton1 obj={dropdownObj} />
                    </td>
                );
            }

            newRows.push(
                <tr key={`row-${i}`} className="border-bottom border-200" onClick={handleRowClick}>
                    {cols}
                </tr>
            );
        }

        setHeader(newHeader);
        setRows(newRows);
    }, [rowNum, colNum, props.obj]);
    
    return (
        <>
        <table className="table mb-0 fs--1 border-200 table-borderless">
            <thead className="bg-light"><tr>{header}</tr></thead>
            <tbody>{rows}</tbody>
        </table>
        {props.obj.query && <p className='text-center mt-2' id='loading-text'>Loading more data</p>}
        </>
    );
}

export default LazyLoadingTable;
