/*global $ record_user_action*/
import React, {Component} from 'react';
import Dropzone from 'react-dropzone';

class Dropzone1 extends Component {
  constructor(props) {
    super(props);
    this.state = {obj: this.props.obj}
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onDrop = (files) => {
      var filesarr = []
      files.forEach(function(file){
        filesarr.push(file)
      })
      this.setState({files: filesarr})
    };
    this.state = {
      files: []
    };
 
  }
  
  handleSubmit(e){
    if(!!e){
      var files = this.state.files
      if(files.length>0){
        $('#alert').hide();
        $('#loading').show()

        for(var i=0; i<files.length; i++){
          var formData = new FormData()
          formData.append('file', files[i])
          const req_url = "/api/v1/upload";
          const request = new XMLHttpRequest();
        
          request.open("POST", req_url, true);
          request.onreadystatechange = () => {
            if (request.readyState === 4 && request.status === 200) {
              var post_obj = JSON.parse(request.responseText)
              post_obj['docname'] = this.props.obj.docname
              post_obj['doctype'] = 'File'
              post_obj['doc_id'] = this.props.obj.doc_id
              if(this.props.obj.solution_id){
                post_obj['solution_id'] = this.props.obj.solution_id
              }
              if(this.props.obj.user_id){
                post_obj['user_id'] = this.props.obj.user_id
              }
              if(this.props.obj.solution_template_id){
                post_obj['solution_template_id'] = this.props.obj.solution_template_id
              }
              if(this.props.obj.company_id){
                post_obj['company_id'] = this.props.obj.company_id
              }
              var action = this.props.obj.action
              var by = this.props.obj.by
              var donefor = this.props.obj.donefor
              var link = this.props.obj.link
              var details = this.props.obj.details
              var doc_id = this.props.obj.doc_id
              
              let submit_obj = {obj: post_obj}
            
              if(this.props.obj.user_email){
                  submit_obj['user'] = this.props.obj.user_email
              }
              
              if(this.props.obj.key){
                  submit_obj['key'] = this.props.obj.key
              }
              
              $.ajax({
                type: "POST",
                url: '/api/v1/submit_data',
                data: JSON.stringify(submit_obj),
                datatype : "application/json",
                contentType: "application/json; charset=utf-8",
                success: function(data){
                  $('#loading').hide()
                  if(!!action){
                    record_user_action(action,by,donefor,link,details,doc_id)
                  }
                  window.location.reload()
                }
              })
            }
          };
          request.send(formData);
        }
      }else{
        $('#alert').show();
        $('#alert .btn-close').click(function() {
          $(this).parent().hide();
        });
      }
    }
  }

  render() {
    const files = this.state.files.map(file => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ));
    
    const container = {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '10px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out',
      justifyContent: 'center'
    };

    return (
      <div className="row">
        <Dropzone onDrop={this.onDrop}>
          {({getRootProps, getInputProps}) => (
            <section className="container drop" style={container}>
              <div {...getRootProps({className: 'dropzone'})} >
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
              <aside>
                <ul>{files}</ul>
              </aside>
            </section>
          )}
        </Dropzone>
        <div className="alert alert-danger" id='alert' role="alert" style={{display: 'none'}}>
          Please select a file first. Click the dropbox above.
          <button type="button" className="btn-close" aria-label="Close" style={{float: 'right'}}></button>
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-primary mt-2 mb-2" onClick={this.handleSubmit}>Upload</button>
        </div>
      </div>
    );
  }
}

export default Dropzone1