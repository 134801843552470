import React, { useState } from 'react';

const Carousel = (props) => {
  const images = props.obj.arr;
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const carouselStyle = {
    position: 'relative',
    width: '100%',
    height: '400px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  };

  const iconStyle = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    fontSize: '24px',
    color: 'black',
    background: 'rgba(255, 255, 255, 0.7)',
    padding: '10px',
    borderRadius: '50%',
    transition: 'color 0.3s ease, background 0.3s ease', 
  };

  const leftIconStyle = {
    ...iconStyle,
    left: '10px',
  };

  const rightIconStyle = {
    ...iconStyle,
    right: '10px',
  };

const imageContainerStyle = {
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  transition: 'opacity 0.3s ease', 
};

const imageStyle = {
  maxHeight: '400px',
  maxWidth: '100%',
  objectFit: 'contain',
  opacity: 0, 
  transition: 'opacity 0.3s ease', 
};

  return (
  <div style={carouselStyle}>
    <div style={leftIconStyle} onClick={goToPrevious}>
      <span className="fas fa-chevron-left"></span>
    </div>
    <div style={imageContainerStyle}>
      <img
        src={images[currentIndex]}
        alt={`Image ${currentIndex}`}
        style={{ ...imageStyle, opacity: 1 }} 
      />
    </div>
    <div style={rightIconStyle} onClick={goToNext}>
      <span className="fas fa-chevron-right"></span>
    </div>
  </div>
);
};

export default Carousel;
