import React from "react";
import ReactDOM from 'react-dom';
import $ from 'jquery';

class Listing extends React.Component{
      constructor(props) {
      super(props)
      console.log(this.props)
      this.handleClick = this.handleClick.bind(this)
      this.handleSubmit = this.handleSubmit.bind(this)
    }
    
    handleClick(event){
      console.log(event)
      console.log(event.target)
      //var indx = Number(event.target.getAttribute('indx'))
      var indx = event.target.getAttribute('indx')
      console.log(indx)
      console.log(this.props.files[indx])
      var file_url = {fileArr: [this.props.files[indx]]}
      var file_obj_str = JSON.stringify(file_url);
      var file_req_url = "/api/v1/script_api?email="+this.props.email+"&sol=2&function=deleteFiles&values[]="+encodeURIComponent(file_obj_str) ;
      $.get(file_req_url, function(data, status){
      });
      this.props.files.splice(indx,1)
      var urls = this.props.files.join(",") + ","
      if(this.props.fileNames[indx]){
        this.props.fileNames.splice(indx,1)
        var fileNames = this.props.fileNames.join(",") + ","
      }
      var obj1 = {
        ssid: this.props.ssid,
        sht:this.props.sht,
        rowNum: this.props.rowNum,
        email: this.props.email,
        fields: [{label: this.props.fileColumn, value: urls, type: 'text'},
                {label: this.props.fileNameColumn, value: fileNames, type: 'text'}]
      }
      var obj_str = JSON.stringify(obj1)
      var req_url = "/api/v1/script_api?email="+this.props.email+"&sol=2&function=saveNew&values[]="+encodeURIComponent(obj_str) ;
      $.get(req_url, function(data, status){
        window.location.reload();
      });
      /*if(this.props.obj.link == '#'){return}
      if(event.target.getAttribute('href').length>0){
          window.open(event.target.getAttribute('href'))
      }*/
    }
    
    handleSubmit(event){
      
    }
    
    render(){
    var fileNum = this.props.files.length
    var items = []
    for(var i=0; i<fileNum; i++){
      var fileName = this.props.fileNames.length >0 ? this.props.fileNames[i] : 'File'+(i+1).toString()
      items.push(<div className="col-md-2 mb-3">
                  <div class="" align="center" >
                    <a href={this.props.files[i]} target="_blank">
                      <img class="img-thumbnail img-fluid shadow-sm" src={this.props.thumbnails[i]} alt="" width="250" /><br/>
                      <p class="fs-0">
                        { fileName }
                      </p>
                    </a>
                    <button class="btn btn-danger btn-sm" onClick={this.handleClick} indx={i} >
                      DELETE
                    </button>
                  </div>  
                </div>)
        
    }

    return  <div class="row">{items}</div>
    }
}

export default Listing