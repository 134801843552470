import React from "react";
import ReactDOM from 'react-dom';
import DropdownButton1 from '../components/DropdownButton1';
import $ from 'jquery';

//import DataTable from 'datatables.net';

//$.DataTable = DataTable

class Table1 extends React.Component{
    constructor(props) {
        super(props)
        this.handleRowClick = this.handleRowClick.bind(this);
    }
    
    handleRowClick(event){
        if(this.props.obj.link == '#'){return}
        if(event.target.getAttribute('href') && event.target.getAttribute('href').length>0){
            window.open(event.target.getAttribute('href'))
        }
    }
    handleDelete(oid){
        let text ='Are you sure you want to delete this data?'
        if(confirm(text)){
            $('#loading').show();
            let delete_obj = {id: oid}
            
            if(this.props.obj.user_email){
                delete_obj['user'] = this.props.obj.user_email
            }
            
            if(this.props.obj.key){
                delete_obj['key'] = this.props.obj.key
            }
            $.ajax({
                type: "POST",
                url: '/api/v1/delete_data',
                data: JSON.stringify(delete_obj),
                datatype : "application/json",
                contentType: "application/json; charset=utf-8",
                success: function(data){
                    console.log(data)
                    alert('Successfully deleted');
                    window.location.reload()
                },
                error: function(err){
                    $('#loading').hide();
                    console.log(err)
                    alert('Something went wrong');
                }
            })
        }
    }
    
    componentDidMount() {
        //$('.table').DataTable();
    }
    
    render(){
    var rowNum = this.props.obj.arr.length
    var colNum = this.props.obj.headers.length
    var rows = []
    var header = []
    for(var i=0; i<rowNum; i++){
        var cols = []
        for(var j=0; j<colNum; j++){
            //var first_key = this.props.obj.headers[0]
            //if(this.props.obj.flags[j]=="Y" || this.props.obj.flags[j]=="ALL"){ //&& !!this.props.obj.json[i][first_key] 
                var key = this.props.obj.headers[j]['key']
                var label = this.props.obj.headers[j]['label']
                if(i==0){
                    header.push(<th key={`header${j}`}>{label}</th>)
                }
                var link = this.props.obj.link ? this.props.obj.link + "?oid=" + this.props.obj.arr[i]["_id"]['$oid'] : this.props.obj.arr[i]['link']
                if(typeof(this.props.obj.arr[i][key])=='number'){
                    cols.push(<td href={link} key={`${j}-${this.props.obj.arr[i]["_id"]['$oid']}`}>{this.props.obj.arr[i][key].toFixed(0)}</td>)
                }else{
                    cols.push(<td href={link} key={`${j}-${this.props.obj.arr[i]["_id"]['$oid']}`}>{this.props.obj.arr[i][key]}</td>)
                }
            //}
        }
        if(!!this.props.obj.manageButton){
            var dropdownObj = {
                className: this.props.obj.manageButton.className,
                btnText: this.props.obj.manageButton.btnText,
                _id: this.props.obj.arr[i]["_id"]['$oid'].toString(),
                options: this.props.obj.manageButton.options,
                snum: i,
                allowDeletion: this.props.obj.allowDeletion
            }
            if(i==0){
                header.push(<th key={`dropdown${i}`}>{this.props.obj.manageButton.headerLabel}</th>)
            }
            let oid = this.props.obj.arr[i]["_id"]['$oid']
            cols.push(<td key={`dropdownItem${i}`}><DropdownButton1 obj={dropdownObj} onDelete={() => this.handleDelete(oid)}/></td>)
        }else{
            if(this.props.obj.allowDeletion){
                if(i==0){
                    header.push(<th key={`deleteHeader${i}`}>Delete</th>)
                }
                let oid = this.props.obj.arr[i]["_id"]['$oid']
                cols.push(<td key={`deleteBtn${i}`}><button className='btn btn-transparent' onClick={()=>{this.handleDelete(oid)}}><svg className="svg-inline--fa fa-trash fa-w-14 text-danger" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path></svg></button></td>)
            }
        }
        rows.push(<tr key={`row${i}`} className="border-bottom border-200"  onClick={this.handleRowClick}>{cols}</tr>)
    }

    return <table className="table mb-0 fs--1 border-200 table-borderless"> 
                <thead className="bg-light"><tr>{header}</tr></thead>
                <tbody>{rows}</tbody>
            </table>
    }
}

export default Table1