import React, { useState, useRef, useEffect } from "react";
import $ from 'jquery';



// Props Required 
// <% disscussion_obj = {
//     msg:messages,
//     task_id:params[:oid],
//     solution_id: @solution.id,
//     company_id: current_company.id,
//     user_id: current_user.id,
//     docname:'Messages',
//     doctype:'Entry'
//     by:current_user.first_name,
//     max_height:
//     btn: {label: 'Submit', className: 'btn btn-primary'},
//     label:'Comment',
// }%>

// <% messages = [{ message: message, user_id: user_id, created_at: created_at, sender_name: currentuser } ]%>



const Discussion = (props) => {
  let {solution_id,company_id,user_id,docname,doctype,task_id,msg,by} = props.obj
  const [messages, setMessages] = useState(msg);
  const [newMessage, setNewMessage] = useState("");
  const chatWindowRef = useRef(null);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (newMessage.trim() !== "") {
      
      let post_obj={}
      
      post_obj['solution_id'] = solution_id
      post_obj['company_id'] = company_id
      post_obj['user_id'] = user_id
      post_obj['task_id'] = task_id
      post_obj['docname'] = docname
      post_obj['doctype'] = doctype
      post_obj['message'] = newMessage
      post_obj['sender_name'] = by
      
      let submit_obj = {obj: post_obj}
      
      if(props.obj.collection_name){
        submit_obj['collection_name'] = props.obj.collection_name
      }
      
      if(props.obj.user_email){
        submit_obj['user'] = props.obj.user_email
      }
      if(props.obj.key){
        submit_obj['key'] = props.obj.key
      }
      
      var req_url = '/api/v1/submit_data'
      var req_data = JSON.stringify(submit_obj)
      
      $.ajax({
        type: "POST",
        url: req_url,
        data: req_data,
        datatype : "application/json",
        contentType: "application/json; charset=utf-8",
        success: function(data){
          console.log(data)
              setMessages([...messages,{ message: newMessage, user_id: user_id, created_at: new Date(),sender_name:by },]);
              setNewMessage("");
              // record_user_action('Message Sent',user_id,data['doc_id']['$oid'])
    
            $('button').prop('disabled', false)
             
        },
        error: function(err){
          console.log(err);
          alert('Something went wrong! Please try again.')
          $(event.target).find('.alert-danger').show();
        }
      });
    }
  };
  useEffect(() => {
    chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
  }, [messages]);
const formatTimestamp = (timestamp) => {
  const today = new Date();
  const messageDate = new Date(timestamp);
  const timeDifference = Math.abs(today - messageDate) / 1000 / 60; // Time difference in minutes

  if (timeDifference < 2) {
    return "just now";
  } else if (timeDifference < 60) {
    return `${Math.floor(timeDifference)} mins ago`;
  } else if (
    messageDate.getDate() === today.getDate() &&
    messageDate.getMonth() === today.getMonth() &&
    messageDate.getFullYear() === today.getFullYear()
  ) {
    // Message is from today, show "Today" along with time
    const formattedTime = messageDate.toLocaleTimeString('en-GB', {
      hour: "2-digit",
      minute: "2-digit",
      // hour12: true,
   
    });
    return `Today: ${formattedTime}`;
  } else if (
    messageDate.getDate() === today.getDate() - 1 &&
    messageDate.getMonth() === today.getMonth() &&
    messageDate.getFullYear() === today.getFullYear()
  ) {
    // Message is from yesterday, show "Yesterday" along with time
    const formattedTime = messageDate.toLocaleTimeString('en-GB', {
      hour: "2-digit",
      minute: "2-digit",
      // hour12: true,

      
    });
    return `Yesterday: ${formattedTime}`;
  } else {
    // Message is from a previous date, show the regular formatted date and time
    return messageDate.toLocaleTimeString('en-GB', {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      // hour12: true,
    
    });

  }
};


  return (

      <div className="chatbox my-2 p-2 d-flex flex-column justify-content-end" style={{maxHeight: props.obj.max_height?props.obj.max_height:'',}}>
        <div
          className="chatbox-messages scrollbar"
          style={{ flex: "70%", overflowY: "auto" }}
          ref={chatWindowRef}
        >
          
          {messages.map((message, index) => (
            <div
              className={`message ${
                message.user_id == user_id ? "sent" : "received"
              }`}
              key={index}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "10px",
              }}
            >
            <div className="flex-1 mt-2">
                <p className="mb-1 bg-soft-warning rounded-3 p-2"><strong>{message.sender_name}:</strong> {message.message}</p>
                <div className="px-2 fs--1"> ⌛ {formatTimestamp(message.created_at)}</div>
            </div>
       

              
            </div>
          ))}
        </div>
        <form className="chatbox-form" onSubmit={handleSendMessage}>
          <label className="form-label mt-4">{props.obj.label?props.obj.label:''}</label>
          <textarea  
            type="text"
            className="form-control my-2"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          ></textarea>
          <button type="submit" className = {props.obj.btn ? props.obj.btn.className? props.obj.btn.className: "btn btn-primary": "btn btn-primary"}>{props.obj.btn ? props.obj.btn.label? props.obj.btn.label: "Submit": "Submit"}</button>

        </form>
      </div>

  );
};

export default Discussion;

