import React from "react";
import ReactDOM from 'react-dom';
import $ from 'jquery';

class Todolist1 extends React.Component{
    constructor(props) {
        super(props)
        this.state = {obj: this.props.obj}
        this.handleChange = this.handleChange.bind(this);
    }
    
    handleChange(event){
        let indx = event.target.getAttribute('indx')
        let obj = this.state.obj    
        var post_obj = {
          status: 'Closed'
        }
        var task_obj = obj['json'][indx]
        
        let update_obj = {obj: post_obj, id: obj['json'][indx]['id']}
            
        if(this.props.obj.user_email){
            update_obj['user'] = this.props.obj.user_email
        }
        
        if(this.props.obj.key){
            update_obj['key'] = this.props.obj.key
        }
        
        $.ajax({
          type: "POST",
          url: '/api/v1/update_data',
          data: JSON.stringify(update_obj),
          datatype : "application/json",
          contentType: "application/json; charset=utf-8",
          success: function(data){
            console.log(data)
            record_user_action(task_obj['Task'] + " - task completed",obj['by'],"",obj['link']+"?oid="+ task_obj['id'],"",data['doc_id']['$oid'])
            window.location.reload(true)
          },
          error: function(err){
            console.log(err)
          }
        })
    }

    render(){
        var rowNum = this.props.obj.json.length
        var rows = []
        var months = ["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"]
        var todos = this.state.obj.json.sort(function(x, y){
                                                return new Date(x['Due Date']) - new Date(y['Due Date']);
                                            })
        this.state.obj.json = todos
                                            
        for(var i=0; i<rowNum; i++){
            if(!!this.state.obj.json[i]['Task'] && this.state.obj.json[i]['Status'] != ("Closed"||"Deleted") ){
                var itemId = "todo-" + i.toString();
                var date = new Date(this.props.obj.json[i]['Due Date'])
                var monthVal = months[date.getMonth()]
                var dateVal = date.getDate()
                var checkbox = this.props.obj.insertcheckbox ?
                              <input className="form-check-input rounded-3 form-check-line-through p-2 mt-0 form-check-input-undefined" type="checkbox" id={itemId} onChange={this.handleChange} indx={i}/>
                              : ""  
                var task = this.props.obj.link ? 
                        (<a href={this.props.obj.link + '?oid=' + this.props.obj.json[i]['id'] } >
                            <span className="text-800 fs-0">{this.props.obj.json[i]['Task']}</span>
                        </a>)
                        : (<span className="text-800 fs-0">{this.props.obj.json[i]['Task']}</span>)
                var priority = this.props.obj.json[i]['Priority'] ?         
                            <small className="badge fw-semi-bold rounded-pill badge-soft-primary p-2">{this.props.obj.json[i]['Priority']}</small> : ""
                var project = this.props.obj.json[i]['Project'] ?
                            <small className="badge fw-semi-bold rounded-pill badge-soft-warning p-2">{this.props.obj.json[i]['Project']}</small> : ""
                
                var owner = []
                if(this.props.obj.json[i]['Owner'] && Array.isArray(this.props.obj.json[i]['Owner']) ){
                  for(let j=0; j<this.props.obj.json[i]['Owner'].length; j++ ){
                    owner.push(<small className="badge fw-semi-bold rounded-pill badge-soft-success p-2">{this.props.obj.json[i]['Owner'][j]}</small>)
                  }
                }else if(this.props.obj.json[i]['Owner']){
                  owner.push(<small className="badge fw-semi-bold rounded-pill badge-soft-success p-2">{this.props.obj.json[i]['Owner']}</small>)
                }         
                var created_by = this.props.obj.json[i]['created_by'] ?         
                            <small className="badge fw-semi-bold rounded-pill badge-soft-info p-2">Created By: {this.props.obj.json[i]['created_by']}</small> : ""
                
                rows.push(<div className="d-flex justify-content-between hover-actions-trigger btn-reveal-trigger px-card hover-bg-100">
                            <div className="form-check mb-0 d-flex align-items-center">
                              {checkbox}
                              <label className="form-check-label mb-0 p-3" >
                                {task}<br/>
                                <span className="fs--1 text-600">{this.props.obj.json[i]['Description']}</span><br/>
                                {priority}{project}{owner}{created_by}
                              </label>
                                <div className="calendar p-0">
                                    <span className="calendar-month fs--1">{monthVal}</span>
                                    <span className="calendar-day fs-0">{dateVal}</span>
                                </div>
                            </div>
                          </div>)
            }
        }
        return <div>{rows}</div>
    }
}

export default Todolist1