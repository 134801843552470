import React from "react";
import ReactDOM from 'react-dom';
import { Bar } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

class Barchart extends React.Component{
    constructor(props) {
        super(props)
        //console.log(this.props)
    }
    
    render(){
    var options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: this.props.obj.chartname,
          },
        },
        scales: {
          x: {
            type: 'time',
            time: {
              // Luxon format string
                tooltipFormat: 'Date',
                unit: 'day',
                displayFormats: {
                   'day': 'MMM dd'
                }
            },
            title: {
              display: true,
              text: 'Date'
            }
          },
          y: {
            title: {
              display: true,
              text: 'value'
            }
          }
        },
        elements: {
          point:{
              radius: 0
          }
        }
      };
      
      let datasets = this.props.obj.datasets
      
      var labels = []
      var chartDatasets = []
      let period = Math.ceil((new Date(this.props.obj.endDate)-new Date(this.props.obj.startDate))/(1000 * 60 * 60 * 24))
      let step = this.props.obj.step ? this.props.obj.step : 1
      let count = period / step
      for(var j=0; j<datasets.length; j++){      
        var values = []
        for(var i=0; i<count; i++){
          var date = new Date(new Date(this.props.obj.startDate).getTime() + ((i+1)*step*24*60*60*1000))
          var prev_date = new Date(new Date(this.props.obj.startDate).getTime() + (i*step*24*60*60*1000))
          labels.push(prev_date)
          var val = datasets[j]['data'].filter(function(v){
            return prev_date < new Date(v['date']) && new Date(v['date']) <= date;
          }).length
          values.push(val)
        }
       
        chartDatasets.push({
          label: this.props.obj.datasets[j]['label'],
          data: values,
          borderColor: datasets[j]['borderColor'],
          backgroundColor: datasets[j]['backgroundColor']
        })
      }
      
      var data = {
        labels,
        datasets: chartDatasets
      }
    
    return <div><Bar options={options} data={data} /></div>;
    }
}

export default Barchart