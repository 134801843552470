import React from "react";
import ReactDOM from 'react-dom';
import ReactEcharts from "echarts-for-react"; 

class LineChart1 extends React.Component{
  constructor(props) {
        super(props)
        console.log(this.props);
  }
  render(){
     var data = this.props.obj.chartData;
  
  var endDate = new Date();
  var startDate = new Date(new Date().setDate(endDate.getDate() - 29));

  var duration = (endDate.getTime() - startDate.getTime())/(24*60*60*1000);
  console.log(duration);

  var months = ["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"];
  var siteSurveysArr = [];
  var designArr = [];
  var ordersArr = [];
  var proposalArr = [];
  var dateArr = [];
  for(var i=0; i<=duration; i++){
    var idate = new Date(startDate.getTime()+i*24*60*60*1000);
    dateArr.push(months[idate.getMonth()]+"-"+idate.getDate());
    idate = idate.toISOString().slice(0, 10);
    var siteSurveysCount = 0;
    var designCount = 0;
    var ordersCount = 0;
    var proposalCount = 0;
    data.forEach((item)=>{
        var date = new Date(item.updated_at);
        var formattedDate = date.toISOString().slice(0, 10);
        if(formattedDate == idate && item.action.includes("Site")){
            siteSurveysCount++;
        }
        if(formattedDate == idate && item.action.includes("Design")){
            designCount++;
        }
        if(formattedDate == idate && item.action.includes("PO")){
            ordersCount++;
        }
        if(formattedDate == idate && item.action.includes("Proposal")){
            proposalCount++;
        }
    });
    siteSurveysArr.push(siteSurveysCount);
    designArr.push(designCount);
    ordersArr.push(ordersCount);
    proposalArr.push(proposalCount);
    
    
    
    
    
}
  
 const option = {
  title: {
        text: this.props.obj.chartname,
        left: 'center'
      },
      grid: {
        top:100,
        bottom: 70
      },
      tooltip: {
        trigger: 'axis'
      },
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: 'none'
          },
          restore: {},
          saveAsImage: {}
        }
      },
      
      legend: {
        data: this.props.obj.fields,
        left: 10
      },
    xAxis: {
    type: 'category',
    data: dateArr
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      data: siteSurveysArr,
      type: 'line',
      name: 'Site Surveys'
    },
    {
      data: designArr,
      type: 'line',
       name: 'Engineering Design',
    },
    {
      data:ordersArr,
      type: 'line',
      name: 'Orders'
    },
    {
      data: proposalArr,
      type: 'line',
      name: 'Proposals'
    }
  ]
}; 
return <ReactEcharts option={option} />;
  }
} 

export default LineChart1